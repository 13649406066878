import { jsxs, jsx } from 'react/jsx-runtime';
import { Link, Navbar } from '@sketch/components';
import { useScrollTop } from '@sketch/utils';
import { SKETCH_WEBSITE } from '@sketch/env-config';
import { Header, LogoWrapper, Logo } from './IndexLayout.styles.js';

const IndexLayoutHeader = ({ disableLogoLink, children, }) => {
    const { isScrollTop } = useScrollTop();
    return (jsxs(Header, Object.assign({ "data-scrolltop": isScrollTop }, { children: [jsx(LogoWrapper, Object.assign({ "data-testid": "sketch-logo" }, { children: disableLogoLink ? (jsx(Logo, {})) : (jsx(Link, Object.assign({ href: `${SKETCH_WEBSITE}/home`, external: true }, { children: jsx(Logo, {}) }))) })), jsx(Navbar.Section, Object.assign({ align: "start" }, { children: children }))] })));
};

export { IndexLayoutHeader };
