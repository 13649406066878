import { jsx } from 'react/jsx-runtime';
import React, { useRef, useEffect, useMemo, useCallback } from 'react';
import ReactDOM from 'react-dom';
import '../observable/index.js';
import { useObservable, Observable } from '../observable/observable.js';

const defaultContextState = {};
const defaultContextSetState = () => { };
const contextDefaultState = [defaultContextState, defaultContextSetState];
const LayoutPortalsContext = React.createContext(contextDefaultState);
const LayoutPortalsProvider = ({ children }) => {
    const value = React.useState(defaultContextState);
    return (jsx(LayoutPortalsContext.Provider, Object.assign({ value: value }, { children: children })));
};
function LayoutPortalRaw({ children, container }) {
    const ref = useObservable(container);
    return ref ? ReactDOM.createPortal(children, ref) : null;
}
/**
 * Creates a layout portal, it also stores the portal in a context.
 */
const useCreateLayoutPortal = () => {
    // We are using useRef as a replacement of useMemo for a single reason
    // using useRef doesn't require to pass this variable to dependencies array of other hooks
    const portalContainerRef = useRef(new Observable(null));
    const [, setPortalsContext] = React.useContext(LayoutPortalsContext);
    useEffect(() => {
        return () => {
            // When the component unmounts we want to reset the portals context, this
            // fixes an issue where portals stored in the context wouldn't render
            // when changing routes because of changes in the DOM container
            setPortalsContext({});
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // To make it easier to deal with this component later on, we are stabilizing
    // the reference of this component, i.e. we are aiming to make that dependencies
    // array would be empty
    const Portal = useMemo(() => function Portal(props) {
        const { children } = props;
        return (jsx(LayoutPortalRaw, Object.assign({ container: portalContainerRef.current }, { children: children })));
    }, []);
    const setPortalContainerRef = useCallback((node) => {
        if (node === null)
            return;
        portalContainerRef.current.setState(node);
        setPortalsContext(portals => {
            // We don't want to store the portal in a context if it's already there
            // or if the id is missing
            if (!node.id || portals[node.id]) {
                // returning the current value should skip re-render
                return portals;
            }
            return Object.assign(Object.assign({}, portals), { [node.id]: Portal });
        });
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
    return [Portal, setPortalContainerRef];
};
/**
 * Retieves a layout portal from the context, it's an alternative way to use an
 * existing layout portal. Apart from this hook we pass down the layout props
 * from the layout to the child component, but this isn't convenient if you
 * need to use the portal in a component down in the React render tree.
 */
const useLayoutPortal = (id) => {
    const contextValue = React.useContext(LayoutPortalsContext);
    if (contextValue === contextDefaultState) {
        throw new Error('useLayoutPortal must be used within a LayoutPortalsProvider');
    }
    const portalContext = contextValue[0];
    if (!portalContext[id]) {
        throw new Error(`There's no layout portal with the id ${id}`);
    }
    return portalContext[id];
};

export { LayoutPortalRaw, LayoutPortalsProvider, useCreateLayoutPortal, useLayoutPortal };
