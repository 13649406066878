import React from 'react'
import { dataIdFromObject } from '@sketch/graphql-cache'

import { handleFetchMore, InfiniteList } from '@sketch/components'
import { WorkspacePermissionGroupMembershipFragment } from '@sketch/gql-types'
import { PermissionGroupItem } from '@sketch/modules-common'

import { BaseUserPermissionRow } from '../../components/UserPermissionRow/UserPermissionRow.styles'

import {
  UserPermissionsTabContent,
  buildPlaceholderArray,
  EmptyContent,
} from './UserPermissionsContent'
import { useManageUserPermissionGroupPermissions } from './useManageUserPermissions'
import RemovePermissionGroupMember from '../../components/RemovePermissionGroupMember'

type GroupPermissionsTabProps = {
  workspaceIdentifier: string
  workspaceMembershipIdentifier: string
  rows: number
}

const GroupPermissionsRow = ({
  membership,
}: {
  membership: WorkspacePermissionGroupMembershipFragment
}) => {
  return (
    <BaseUserPermissionRow
      name={membership.permissionGroup.name}
      actions={
        <RemovePermissionGroupMember memberIdentifier={membership.identifier} />
      }
      showStripes
    >
      <PermissionGroupItem permissionGroup={membership.permissionGroup} />
    </BaseUserPermissionRow>
  )
}

export const GroupPermissionsTab = ({
  workspaceIdentifier,
  rows,
  workspaceMembershipIdentifier,
}: GroupPermissionsTabProps) => {
  const groups = useManageUserPermissionGroupPermissions({
    workspaceIdentifier,
    workspaceMembershipIdentifier,
    fetchPermissionGroup: true,
  })

  const total = groups.total
  const projectsLength = groups?.data?.length || 0

  if (groups.loading && projectsLength === 0) {
    return (
      <UserPermissionsTabContent rows={rows} onSearch={groups.setGroupSearch}>
        {buildPlaceholderArray(2)}
      </UserPermissionsTabContent>
    )
  }

  if (!groups.loading && total === 0) {
    return (
      <UserPermissionsTabContent rows={rows} onSearch={groups.setGroupSearch}>
        {groups.isUsingFilter ? (
          <EmptyContent
            title="No search results"
            subtitle="No project names matched your search."
          />
        ) : (
          <EmptyContent
            title="No groups"
            subtitle="This person hasn’t been added to any groups yet."
          />
        )}
      </UserPermissionsTabContent>
    )
  }

  return (
    <UserPermissionsTabContent rows={rows} onSearch={groups.setGroupSearch}>
      <InfiniteList
        renderLoading={() => null}
        placeholderItems={buildPlaceholderArray(total - projectsLength)}
        onLoadMore={handleFetchMore(groups.fetchMore, groups.path, {
          dataIdFromObject,
          after: groups.after,
        })}
        canLoadMore={groups.after !== null}
        data-testid="group-permissions-tab"
      >
        {groups.data?.map(membership => (
          <GroupPermissionsRow
            key={membership.identifier}
            membership={membership}
          />
        ))}
      </InfiniteList>
    </UserPermissionsTabContent>
  )
}
