import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { useToast } from '@sketch/toasts';
import { LinkButton, Link } from '@sketch/components';
import { ForgotPasswordForm } from '@sketch/user';
import { getIsForInAppPresentation, IndexLayoutContent, IndexLayoutHeaderLink, IndexLayoutOldTitle, IndexLayoutOldSubtitle, routes } from '@sketch/modules-common';
import { Wrapper, Line, Text } from './ForgotPasswordView.styles.js';
import { FormContainer } from '../SignInView/SignInView.styles.js';

const ForgotPasswordView = props => {
    const { location: { state }, HeaderPortal, } = props;
    const { showToast } = useToast();
    const isForInAppPresentation = getIsForInAppPresentation();
    return (jsxs(IndexLayoutContent, Object.assign({ center: "horizontal", marginTop: true, paddingHorizontal: true }, { children: [jsx(HeaderPortal, { children: jsx(IndexLayoutHeaderLink, {}) }), jsx(FormContainer, { children: jsx(ForgotPasswordForm, Object.assign({ onCompleted: data => {
                        var _a;
                        const errors = ((_a = data.userRequestPasswordReset) === null || _a === void 0 ? void 0 : _a.errors) || [];
                        if (errors.length > 0) {
                            const apiErrors = errors.map(error => error === null || error === void 0 ? void 0 : error.message);
                            showToast(apiErrors.join('; '), 'negative');
                        }
                    }, onError: error => {
                        showToast(error.message, 'negative');
                    } }, { children: formikProps => formikProps.successful ? (jsxs(Wrapper, { children: [jsx(IndexLayoutOldTitle, { children: "Forgot Your Password?" }), jsx(IndexLayoutOldSubtitle, { children: "If there is an account associated with this email address you will receive a password reset email." }), jsx(LinkButton, Object.assign({ to: { pathname: routes.SIGN_IN.create({}), state }, variant: "primary" }, { children: "Go back to Sign in" }))] })) : (jsxs(Fragment, { children: [jsx(IndexLayoutOldTitle, { children: "Forgot Your Password?" }), jsx(IndexLayoutOldSubtitle, { children: "Enter the email address you use to sign in to Sketch." }), jsx(ForgotPasswordForm.Fields, Object.assign({}, formikProps)), jsx(ForgotPasswordForm.Submit, Object.assign({}, formikProps)), !isForInAppPresentation && (jsxs(Fragment, { children: [jsx(Line, {}), jsxs(Text, { children: ["Already have a Sketch account?", ' ', jsx(Link, Object.assign({ variant: "primary", to: routes.SIGN_IN.create({}), isUnderlined: true }, { children: "Sign in" }))] }), jsxs(Text, { children: ["New to Sketch?", ' ', jsx(Link, Object.assign({ variant: "primary", isUnderlined: true, to: routes.SIGN_UP.create({}) }, { children: "Create an account" }))] })] }))] })) })) })] })));
};

export { ForgotPasswordView as default };
