import styled, { css } from 'styled-components';
import { breakpoint } from '@sketch/global-styles';
import '../Button/index.js';
import { ReactComponent } from '@sketch/icons/workspace-16';
import { ButtonUnstyled } from '../Button/ButtonUnstyled.js';

const hideMargins = (margins) => {
    switch (margins) {
        case 'both':
            return css `
        :nth-child(1) {
          margin-top: 0;
        }

        :nth-last-child(1) {
          margin-bottom: 0;
        }
      `;
        case 'top':
            return css `
        :nth-child(1) {
          margin-top: 0;
        }
      `;
        case 'bottom':
            return css `
        :nth-last-child(1) {
          margin-bottom: 0;
        }
      `;
    }
};
const WorkspaceIcon = styled(ReactComponent) `
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.brand.teal.foreground};
`;
const CloseButton = styled(ButtonUnstyled) `
  margin-left: 8px;
  flex-shrink: 0;

  width: 24px;
  height: 24px;
`;
const CloseButtonUpsell = styled(ButtonUnstyled) `
  margin-left: 8px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.brand.teal.foreground};
  width: 16px;
  height: 16px;
`;
const IconWrapper = styled.div `
  width: 16px;
  height: 16px;
  margin: 4px 8px 4px 0px;
  line-height: 0;
  flex-shrink: 0;
`;
const Wrapper = styled.aside(({ theme, $type }) => css `
    display: flex;
    min-height: 40px;

    ${$type !== 'custom' &&
    css `
      padding: 8px 16px 8px 16px;
      border-radius: ${theme.radii.large};

      /* To affect both the icon and text */
      color: ${theme.colors.foreground.secondary.C};
    `}

    /* Give a margin for sibling elements */
    & + * {
      margin-top: 8px;
    }

    ${() => {
    switch ($type) {
        case 'error': {
            return css `
            background-color: ${theme.colors.state.negative.F};

            ${CloseButton}, ${IconWrapper} {
              color: ${theme.colors.state.negative.A};
            }
          `;
        }
        case 'warning': {
            return css `
            background-color: ${theme.colors.state.neutral.F};

            ${CloseButton}, ${IconWrapper} {
              color: ${theme.colors.state.warning};
            }
          `;
        }
        case 'upsell': {
            return css `
            background-color: ${theme.colors.brand.teal.default.F};
            border: 1px solid ${theme.colors.border.B};

            ${CloseButton}, ${IconWrapper} {
              color: ${theme.colors.state.warning};
            }
          `;
        }
        case 'information': {
            return css `
            border: 1px solid ${theme.colors.border.B};

            ${Text} {
              color: ${theme.colors.foreground.secondary.B};
            }
          `;
        }
    }
}}

    b {
      font-weight: ${theme.fontWeights.medium};
      color: ${theme.colors.foreground.secondary.A};
    }
  `);
const Text = styled.span `
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.D};
  color: ${({ theme }) => theme.colors.foreground.secondary.B};
  font-weight: 400;
  line-height: 1.4;
  margin: 4px auto 2px 0; /* stylelint-disable-line scales/space */
  align-self: center;

  a:not([class]) {
    color: ${({ theme }) => theme.colors.foreground.secondary.A};
    font-weight: ${({ theme }) => theme.fontWeights.semibold};
    text-decoration: underline;
  }
`;
const BannerPageWrapper = styled.div `
  display: flex;
  flex-direction: column;

  ${Wrapper} + ${Wrapper} {
    margin-top: 8px;
  }

  ${Wrapper} {
    :nth-child(1) {
      margin-top: 16px;
    }

    :nth-last-child(1) {
      margin-bottom: 16px;
    }

    ${breakpoint('sm') `
      :nth-child(1) {
        margin-top: 40px;
      }

      :nth-last-child(1) {
        margin-bottom: 40px;
      }
    `}

    ${({ $hideMargins }) => hideMargins($hideMargins)}
  }
`;

export { BannerPageWrapper, CloseButton, CloseButtonUpsell, IconWrapper, Text, WorkspaceIcon, Wrapper };
