import React from 'react'
import { useParams } from 'react-router-dom'
import { RouteParams } from '@sketch/modules-common'

import { useToast } from '@sketch/toasts'

import { ChangeRoleConfirmationModal } from './ChangeRoleConfirmationModal'
import {
  Pill,
  Tooltip,
  Text,
  Dropdown,
  VisuallyHidden,
  useModalContext,
  ConditionalWrapper,
} from '@sketch/components'

import {
  TableButton,
  EllipsisIcon,
  TooltipImageWrapper,
  Image,
  DropdownHeader,
  DropdownItemDescription,
  WrapperItem,
} from './MemberActions.styles'

import {
  WorkspaceMembershipFragment,
  BillingSeatsInfoFragment,
  BillingPlanFragment,
  PaymentDetailsFragment,
  useResendWorkspaceInviteMutation,
} from '@sketch/gql-types'

// Images
import TooltipImageSrc from '@sketch/icons/highlight-image-tooltip.png'
import TooltipRetinaImageSrc from '@sketch/icons/highlight-image-tooltip@2x.png'

// we are importing more specifically here, because modals are importing components from the
// root components index.ts file
import { EditorDowngradeModal } from '../../modals/EditorDowngradeModal/EditorDowngradeModal'
import { ViewerUpgradeModal } from '../../modals/ViewerUpgradeModal/ViewerUpgradeModal'
import { RemoveWorkspaceMemberModal } from '../../modals/RemoveWorkspaceMemberModal/RemoveWorkspaceMemberModal'
import { ManageUserPermissions } from '../../modals/ManageUserPermissionsModal/ManageUserPermissionsModal'
import { ManageUserPermissionsUpsellModal } from '../Upsell/ManageUserPermissionsUpsellModal'

import {
  isWorkspaceSubscriptionCanceled,
  isWorkspaceSubscriptionTrialling,
} from '../../utils/status'

// Usage of BillingStatus enum is needed to make the expected strings match the BE ones
// eslint-disable-next-line no-restricted-imports
import { BillingStatus } from '@sketch/gql-types/expansive'
import { isBillingHidden } from '@sketch/env-config'

interface ChangeMemberRoleDropdownItemProps {
  membershipId: string
  memberName?: string
  workspaceName: string
  userEmail: string
  memberCurrentRole: WorkspaceMembershipFragment['role']
  hasPartner: boolean
}

export const ChangeMemberRoleDropdownItem: React.FC<ChangeMemberRoleDropdownItemProps> = ({
  membershipId,
  memberCurrentRole,
  memberName,
  userEmail,
  workspaceName,
  hasPartner,
}) => {
  const { workspaceId } = useParams<RouteParams<'WORKSPACE_SETTINGS'>>()

  const { showModal } = useModalContext()

  const isAdmin = memberCurrentRole === 'ADMIN'
  const isFinance = memberCurrentRole === 'FINANCE'

  return (
    <>
      <Dropdown.Item
        onClick={() => {
          showModal(ChangeRoleConfirmationModal, {
            variant: 'ADMIN',
            workspaceId,
            workspaceName,
            membershipId,
            memberName,
            userEmail,
            role: memberCurrentRole,
          })
        }}
      >
        {isAdmin ? 'Remove as Admin' : 'Assign as Admin'}
        <DropdownItemDescription>
          {isAdmin
            ? 'Can no longer manage the Workspace, add or remove Members, and edit roles'
            : 'Manage the Workspace, add or remove Members, and edit roles'}
        </DropdownItemDescription>
      </Dropdown.Item>

      {!isBillingHidden && (
        <ConditionalWrapper
          condition={hasPartner}
          wrapper={children => (
            <Tooltip
              placement="top"
              content={
                'You cannot assign somebody as Finance while you have a Sketch Partner'
              }
            >
              {children}
            </Tooltip>
          )}
        >
          <Dropdown.Item
            disabled={hasPartner}
            onClick={() => {
              showModal(ChangeRoleConfirmationModal, {
                variant: 'FINANCE',
                workspaceId,
                membershipId,
                memberName,
                workspaceName,
                userEmail,
                role: memberCurrentRole,
              })
            }}
          >
            {isFinance ? 'Remove as Finance' : 'Assign as Finance'}
            <DropdownItemDescription>
              {isFinance
                ? 'Can no longer manage billing and subscriptions on behalf of Admins'
                : 'Manage billing and subscriptions on behalf of Admins'}
            </DropdownItemDescription>
          </Dropdown.Item>
        </ConditionalWrapper>
      )}
    </>
  )
}

interface ResendMemberInviteProps {
  membershipId: string
  userEmail: string
}

const ResendMemberInvite: React.FC<ResendMemberInviteProps> = props => {
  const { showToast } = useToast()

  const { membershipId, userEmail } = props

  const [resendInvite, { loading }] = useResendWorkspaceInviteMutation({
    variables: { membershipId },
    onCompleted: () => showToast(`Invite to ${userEmail} sent!`),
    onError: 'show-toast',
  })

  return (
    <Dropdown.Item disabled={loading} onClick={() => resendInvite()}>
      Resend Invitation
    </Dropdown.Item>
  )
}

interface MemberActionsProps {
  member: WorkspaceMembershipFragment
  billingStatus: BillingStatus
  customerId: string
  seats: BillingSeatsInfoFragment
  plan: BillingPlanFragment
  paymentDetails?: PaymentDetailsFragment
  workspaceId: string
  workspaceName: string
  isSsoActive: boolean
  hasPartner: boolean
  isPartneredWorkspace: boolean
  isEducationWorkspace?: boolean
  isIOS?: boolean
  showUpsell?: boolean
  userDirectoryEnabled?: boolean
  showWorkspacePermissionGroup?: boolean
  hideUpdateToEditor?: boolean
}

export const MemberActions: React.FC<MemberActionsProps> = props => {
  const {
    billingStatus,
    member,
    customerId,
    seats,
    plan,
    paymentDetails,
    workspaceId,
    isSsoActive,
    workspaceName,
    hasPartner,
    isPartneredWorkspace,
    isEducationWorkspace,
    isIOS,
    showUpsell,
    userDirectoryEnabled,
    showWorkspacePermissionGroup,
    hideUpdateToEditor,
  } = props

  const { showToast } = useToast()
  const { showModal } = useModalContext()

  // For iOS subscription workspace, owners do not have member actions
  if (isIOS && member.isOwner) return null

  const email = member.user?.email || member.invite?.email
  const invitePending = !member.acceptedAt

  const isFinanceViewer = !member.isEditor && member.role === 'FINANCE'
  const isPartner = member.role === 'PARTNER'

  if (isPartner && !invitePending) {
    return null
  }

  const handleEditorButtonClick = () => {
    if (isWorkspaceSubscriptionCanceled(billingStatus)) {
      showToast(
        'You need an active subscription to edit workspace members',
        'negative'
      )
    } else {
      showModal(ViewerUpgradeModal, {
        member,
        isOnTrial: isWorkspaceSubscriptionTrialling(billingStatus),
        customerId,
        seats,
        plan,
        paymentDetails: paymentDetails!,
        showBillingSummary: !isPartneredWorkspace && !isBillingHidden,
      })
    }
  }

  const handleViewerButtonClick = () => {
    if (isWorkspaceSubscriptionCanceled(billingStatus)) {
      showToast(
        'You need an active subscription to edit workspace members',
        'negative'
      )
    } else {
      showModal(EditorDowngradeModal, { member })
    }
  }

  const handleToggleEditorClick = () => {
    if (member.isEditor) {
      handleViewerButtonClick()
    } else {
      handleEditorButtonClick()
    }
  }
  const handleManageUserPermissionsClick = () =>
    showModal(ManageUserPermissions, {
      member,
      workspaceId,
      showWorkspacePermissionGroup,
    })

  const manageUserPermissionsUpsellClick = () =>
    showModal(ManageUserPermissionsUpsellModal, {
      member,
      workspaceId,
    })

  let dropdownContent = null

  const isPartnerNoSeatsAndViewer =
    isPartneredWorkspace && seats.availableSeats === 0 && !member.isEditor

  const isEducationWorkspaceNoSeats =
    !!isEducationWorkspace && seats.availableSeats === 0 && !member.isEditor

  const disabledChangeToEditorTooltipMessage = isEducationWorkspace ? (
    <>No more available Editor Seats</>
  ) : (
    <>
      All Editor Seats in your Workspace are in use. <br />
      You can request more Editor Seats by contacting your Sketch Partner
    </>
  )

  const ShowProjectAccessButton = () => {
    if (!showUpsell) {
      return (
        <Dropdown.Item onClick={handleManageUserPermissionsClick}>
          <WrapperItem>Document & Project access…</WrapperItem>
        </Dropdown.Item>
      )
    }

    return (
      <Tooltip
        placement="right"
        content={
          <>
            <TooltipImageWrapper>
              <Image
                alt="Document Access Upsell"
                src={TooltipImageSrc}
                srcSet={`${TooltipRetinaImageSrc} 2x`}
              />
            </TooltipImageWrapper>
            <Text>Need More Visibility and Control with Document Access?</Text>
            <Text>
              Upgrade to our Business Plan for access to the powerful
              permissions directory. Click for more
            </Text>
          </>
        }
      >
        <Dropdown.Item onClick={manageUserPermissionsUpsellClick}>
          <WrapperItem>
            Document & Project access… <Pill variant="secondary">Business</Pill>
          </WrapperItem>
        </Dropdown.Item>
      </Tooltip>
    )
  }

  if (member.role !== 'GUEST') {
    dropdownContent = (
      <>
        {/**  Finance Viewers cannot be Editors */}
        {!isFinanceViewer && !isPartner && !isIOS && (
          <>
            <DropdownHeader>Access</DropdownHeader>
            <ConditionalWrapper
              condition={
                isPartnerNoSeatsAndViewer || isEducationWorkspaceNoSeats
              }
              wrapper={children => (
                <Tooltip
                  placement="top"
                  content={disabledChangeToEditorTooltipMessage}
                >
                  {children}
                </Tooltip>
              )}
            >
              <>
                <Dropdown.Item
                  onClick={handleToggleEditorClick}
                  disabled={
                    isPartnerNoSeatsAndViewer || isEducationWorkspaceNoSeats
                  }
                >
                  {member.isEditor
                    ? 'Change to Viewer...'
                    : 'Change to Editor...'}
                  <DropdownItemDescription
                    $isDisabled={
                      isPartnerNoSeatsAndViewer || isEducationWorkspaceNoSeats
                    }
                  >
                    {member.isEditor
                      ? 'Browse, inspect and comment on documents in the web app only'
                      : 'Browse, inspect and comment in the web app — and create and edit comments in the Mac app'}
                  </DropdownItemDescription>
                </Dropdown.Item>

                {userDirectoryEnabled && member.isOwner ? (
                  <>
                    <Dropdown.Divider />
                    <ShowProjectAccessButton />
                  </>
                ) : null}
              </>
            </ConditionalWrapper>
          </>
        )}
        {!member.isOwner && (
          <>
            {!isPartner && !isIOS && (
              <>
                {!isFinanceViewer && <Dropdown.Divider />}
                <DropdownHeader>Role</DropdownHeader>
                <ChangeMemberRoleDropdownItem
                  memberName={member.user?.name}
                  userEmail={email!}
                  membershipId={member.identifier}
                  memberCurrentRole={member.role!}
                  workspaceName={workspaceName}
                  hasPartner={hasPartner}
                />

                <Dropdown.Divider />
              </>
            )}

            {invitePending && (
              <ResendMemberInvite
                membershipId={member.identifier}
                userEmail={email!}
              />
            )}

            {!isPartner && (
              <>
                {userDirectoryEnabled && (
                  <>
                    <ShowProjectAccessButton />
                    <Dropdown.Divider />
                  </>
                )}
                <Dropdown.Item
                  intent="negative"
                  onClick={() => {
                    showModal(RemoveWorkspaceMemberModal, {
                      member,
                      workspaceId,
                    })
                  }}
                >
                  Remove from Workspace&hellip;
                </Dropdown.Item>
              </>
            )}
          </>
        )}
      </>
    )
  } else {
    dropdownContent = (
      <>
        {!isSsoActive && (
          <>
            <DropdownHeader>Access</DropdownHeader>
            <Dropdown.Item onClick={handleViewerButtonClick}>
              Change to Member (Viewer)…
              <DropdownItemDescription>
                Browse, inspect and comment on documents in the web app only
              </DropdownItemDescription>
            </Dropdown.Item>
            {/* Hide this menu on MAS workspaces and when the member is a guest */}
            {hideUpdateToEditor ? null : (
              <Dropdown.Item onClick={handleEditorButtonClick}>
                Change to Member (Editor)…
                <DropdownItemDescription>
                  All of the above, and create and edit documents in the Mac app
                </DropdownItemDescription>
              </Dropdown.Item>
            )}
            <Dropdown.Divider />
          </>
        )}

        {userDirectoryEnabled && (
          <>
            <ShowProjectAccessButton />
            <Dropdown.Divider />
          </>
        )}

        <Dropdown.Item
          intent="negative"
          onClick={() => {
            showModal(RemoveWorkspaceMemberModal, { member, workspaceId })
          }}
        >
          Remove from All Documents <br />& Workspace&hellip;
        </Dropdown.Item>
      </>
    )
  }

  return (
    <Dropdown
      contentPadding="12px 0"
      placement="bottom-end"
      /* Ensure the dropdown is wide enough to accomodate all items without wrapping */
      maxWidth="288px"
      /* Force dropdown container to not fill the parent div */
      style={{ display: 'inline-block' }}
      /* This was added because the dropdown was confused if it should render above of bellow and hide itself */
      usePortal
      toggle={
        <TableButton active data-testid="edit-member-access">
          <VisuallyHidden>Edit</VisuallyHidden>
          <EllipsisIcon />
        </TableButton>
      }
    >
      {dropdownContent}
    </Dropdown>
  )
}
