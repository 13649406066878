import { __rest } from 'tslib';
import { jsx, Fragment } from 'react/jsx-runtime';
import { useRef, useLayoutEffect } from 'react';
import styled, { keyframes, css } from 'styled-components';
import '../Popper/index.js';
import { useHover, isTouchDevice } from '@sketch/utils';
import { Popper } from '../Popper/Popper.js';

const reveal = keyframes `
    0% {
      opacity: 0;
      transform: translateY(-5px)
    }
    100% {
      opacity: 1;
      transform: translateY(0)
    }
  `;
const TooltipContent = styled.div(({ theme: { colors, radii, fontWeights, transitions, fontSizes }, scrollable, placement, }) => css `
    /* when there's no title / body structures, we want the tooltip text to look like a title (white text) */
    color: ${colors.ui.tooltips.title};
    background: ${colors.ui.tooltips.background};
    font-size: ${fontSizes.C};
    filter: drop-shadow(0 4px 8px 0 ${colors.ui.tooltips.shadow});
    border-radius: ${radii.xlarge};
    padding: 8px 12px;
    font-weight: ${fontWeights.medium};
    z-index: 9999;
    animation: ${reveal} ${transitions.duration[4]}
      ${transitions.timing.easeOutExponential};
    box-shadow: 0 4px 8px 0 ${colors.ui.tooltips.shadow};
    max-width: 232px;

    /* Forces hardware acceleration in Safari which fixes a persistent shadow bug*/
    transform: translate3d(0, 0, 0);

    mark {
      background: ${colors.sketch.B};
    }

    /* This can be extended for every other tooltip position */
    ${scrollable && placement === 'top'
    ? css `
          &:after {
            content: ' ';
            position: absolute;
            bottom: -10px;
            width: 100%;
            height: 10px;
            left: 0;
          }
        `
    : ''}
  `);
const TooltipTitle = styled.div `
  color: ${({ theme }) => theme.colors.ui.tooltips.title};
`;
const TooltipBody = styled.div `
  color: ${({ theme }) => theme.colors.ui.tooltips.foreground};
`;
const TooltipShortcut = styled.div `
  color: ${({ theme }) => theme.colors.ui.tooltips.shortcutText};

  @media (hover: none) and (pointer: coarse) {
    /* Hide this so mobile devices should not see it */
    display: none;
  }
`;
/**
 * Used for contextual help. For instance, when you need to provide
 * more context to a icon button on hover.
 *
 * It can have different placements, you can check all of them via
 * the props table on Storybook.
 */
const TooltipBase = (_a) => {
    var { children, className, content, disabled = false, disableWhenTouchDevice = true, scrollable = false, placement } = _a, props = __rest(_a, ["children", "className", "content", "disabled", "disableWhenTouchDevice", "scrollable", "placement"]);
    const [visible, hoverEventHandlers, setVisible] = useHover();
    const forceUpdateRef = useRef();
    const forceShow = () => setVisible(true);
    const forceHide = () => setVisible(false);
    useLayoutEffect(() => {
        // We need to call forceUpdate from Popper
        // to reposition the tooltip whenever the content
        // changes, avoiding misalignment.
        // https://github.com/sketch-hq/Cloud/issues/5334
        const forceUpdateFn = forceUpdateRef === null || forceUpdateRef === void 0 ? void 0 : forceUpdateRef.current;
        if (forceUpdateFn) {
            forceUpdateFn();
        }
    }, [content]);
    if (disableWhenTouchDevice && isTouchDevice()) {
        return typeof children === 'function' ? (jsx(Fragment, { children: children({ ref: null }) })) : (jsx(Fragment, { children: children }));
    }
    return (jsx(Popper, Object.assign({ className: className, visible: visible && !disabled, placement: placement, spacing: "5px", onTriggerFocus: forceShow, onTriggerBlur: forceHide }, props, hoverEventHandlers, { popup: (_a) => {
            var { forceUpdate } = _a, popperProps = __rest(_a, ["forceUpdate"]);
            forceUpdateRef.current = forceUpdate;
            return (jsx(TooltipContent, Object.assign({}, popperProps, (scrollable ? hoverEventHandlers : {}), { scrollable: scrollable, placement: placement, "data-testid": "tooltip" }, { children: content })));
        } }, { children: children })));
};
const Tooltip = Object.assign(TooltipBase, {
    Title: TooltipTitle,
    Body: TooltipBody,
    Shortcut: TooltipShortcut,
});

export { Tooltip, TooltipShortcut };
