import { jsx } from 'react/jsx-runtime';
import '../NavbarExtended/index.js';
import '../SplitLayout/index.js';
import { SidebarWrapper, LoadingSidebarWorkspace } from './LoadingWorkspaceSidebarLayout.styles.js';
import '../LoadingState/index.js';
import { SplitLayout } from '../SplitLayout/SplitLayout.js';
import { NavbarExtended } from '../NavbarExtended/Navbar.js';
import { LoadingState } from '../LoadingState/LoadingState.js';

const LoadingWorkspaceSidebarLayout = ({ sidebar, header, }) => (jsx(SplitLayout, Object.assign({ header: header || jsx(NavbarExtended, {}), sidebar: jsx(SidebarWrapper, { children: sidebar || jsx(LoadingSidebarWorkspace, {}) }), footer: null }, { children: jsx(LoadingState, {}) })));

export { LoadingWorkspaceSidebarLayout };
