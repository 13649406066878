import React, { useMemo } from 'react'

import { GenericError, handleFetchMore } from '@sketch/components'
import { dataIdFromObject } from '@sketch/graphql-cache'
import {
  CommunityPublicationQueryFragment,
  PublicationQueryPreferencesFragment,
} from '@sketch/gql-types'
import EmptyState from '../../../shares/components/EmptyState'
import { useTrackEvent } from '@sketch/modules-common'

import PublicationGrid, { PublicationGridPlaceholder } from '../PublicationGrid'
import { useCommunityModifiers, useGetCommunityPublications } from '../../hooks'

const ENTRIES_PATH = ['publications', 'entries']

// We need to use Partial here because of PreventiveCommunityGrid and CommunitySearchPage
interface CommunityPublicationGridProps
  extends Partial<
    Pick<CommunityPublicationQueryFragment, 'title' | 'subTitle' | 'link'>
  > {
  analyticsId: string
  preferences: Partial<PublicationQueryPreferencesFragment> | null
}

const CommunityPublicationGrid = ({
  title,
  link,
  subTitle,
  analyticsId,
  ...rest
}: CommunityPublicationGridProps) => {
  const { allowPagination, search, ...editorialVariables } =
    rest.preferences || {}

  const { trackEvent } = useTrackEvent()
  const [{ sort, period, documentType }] = useCommunityModifiers(
    editorialVariables
  )

  const preferences = {
    ...editorialVariables,
    search,
    sort,
    period,
    documentType,
  }

  const { data, loading, error, fetchMore } = useGetCommunityPublications({
    preferences,
  })

  const isEmptyList = data?.publications.meta.totalCount === 0
  const after = data?.publications.meta.after

  const handleLoadMore = useMemo(() => {
    trackEvent('COMMUNITY - Load More Items', {
      type: 'click',
    })

    return handleFetchMore(fetchMore, ENTRIES_PATH, {
      dataIdFromObject,
      after,
    })
  }, [trackEvent, fetchMore, after])

  if (loading) {
    return (
      <PublicationGridPlaceholder
        limit={rest.preferences?.limit || undefined}
      />
    )
  }

  if (error || !data) {
    return <GenericError />
  }

  const publications = data.publications
  const items = publications.entries || []

  const placeholderCount = allowPagination
    ? Math.max(Math.min(publications.meta.totalCount - items.length, 20), 0)
    : 0

  return isEmptyList ? (
    <EmptyState
      title="No document results"
      description="No document matched your search."
      icon="search"
      search={search || undefined}
    />
  ) : (
    <PublicationGrid
      analyticsId={analyticsId}
      items={items}
      placeholderCount={placeholderCount}
      onLoadMore={allowPagination ? handleLoadMore : undefined}
    />
  )
}

export default CommunityPublicationGrid
