import React from 'react'
import { stringify } from 'query-string'

import { MenuIconButton } from '@sketch/components'
import { useAnnotationOverlayContext } from '../../../annotations/hooks'
import { AnnotationInboxList } from '../../../annotations/components'

import { ChatAddIcon, Wrapper } from './PrototypeAnnotationsSidebar.styles'
import { useQueryParams } from '@sketch/modules-common'

export const PrototypeAnnotationsSidebar = () => {
  const annotationContext = useAnnotationOverlayContext()
  const { annotation, ...otherParams } = useQueryParams<'PROTOTYPE_PLAYER'>()

  let button
  if (annotationContext) {
    const { setPlaceDraftAnnotation, placeDraftAnnotation } = annotationContext
    button = (
      <MenuIconButton
        onClick={(event: MouseEvent) => {
          setPlaceDraftAnnotation?.(!placeDraftAnnotation)
          event.stopPropagation()
        }}
        aria-current={placeDraftAnnotation}
        description="Add comment"
      >
        <span className="sr-only">New comment</span>
        <ChatAddIcon role="img" />
      </MenuIconButton>
    )
  }

  return (
    <Wrapper>
      <AnnotationInboxList
        navbarAction={button}
        activeAnnotationIdentifier={annotationContext?.activeAnnotation}
        resolveAnnotationLink={annotation => {
          if (annotationContext?.status === 'available') {
            return location => ({
              ...location,
              search: stringify({
                ...otherParams,
                annotation: annotation.identifier,
              }),
            })
          }
        }}
      />
    </Wrapper>
  )
}
