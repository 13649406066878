import React from 'react'

import { routes, useFlag } from '@sketch/modules-common'

import { useGetPublications } from '../../hooks/useGetPublications'

import { Skeleton, ErrorMessage, Grid } from '@sketch/components'

import GridPublicationItem from '../../components/GridPublicationItem'

import {
  EmptyState,
  StyledSkeleton,
} from './WorkspaceProfilePublications.styles'

import { ColumnProps } from '@sketch/components/dist/esm/Grid/Grid.types'
import type {
  WorkspaceProfileResultLoading,
  WorkspaceProfileResultSuccess,
} from '../../hooks/useGetWorkspaceProfile'
import { getGridColumnSize } from '../../utils'

interface WorkspaceProfileLayoutProps {
  workspaceId: string
  isProfileEditRoute: boolean
  profileResult: WorkspaceProfileResultSuccess | WorkspaceProfileResultLoading
}

const GUTTER = { xl: 40, lg: 40, md: 40, sm: 40, xs: 24 }
const COLUMN_BREAKPOINTS = {
  sm: 4,
  xs: 6,
  xxs: 12,
} as ColumnProps

const createArray = (numberOfItems: number) =>
  Array.from(Array(numberOfItems).keys())

const DocumentGridSkeleton = () => (
  <Grid gutter={GUTTER}>
    {createArray(6).map(placeholder => {
      return (
        <Grid.Column key={`placeholder-skeleton-${placeholder}`} lg={4}>
          <Skeleton width="100%" height="400px" />
          <StyledSkeleton width="40%" height="14px" />
        </Grid.Column>
      )
    })}
  </Grid>
)

export const WorkspaceProfilePublications = (
  props: WorkspaceProfileLayoutProps
) => {
  const { workspaceId, isProfileEditRoute, profileResult } = props
  const { profile } = profileResult

  /*
    Remove this flag when its released
    https://github.com/sketch-hq/Cloud/issues/16748
   */
  const isProfileDocumentViewFFEnabled = useFlag('profile-document-detail-view')

  const {
    publications,
    placeholders,
    isEmpty,
    error,
    loading,
  } = useGetPublications(workspaceId)

  if (loading) {
    return <DocumentGridSkeleton />
  }

  if (isEmpty) {
    return (
      <EmptyState
        workspaceId={workspaceId}
        workspaceName={profile?.workspace.name!}
        editing={isProfileEditRoute}
      />
    )
  }

  if (error) {
    return <ErrorMessage.Generic />
  }

  const isSingleDocument = publications.length === 1
  const isDoubleDocument = publications.length === 2

  if (isDoubleDocument) {
    return (
      <Grid asList gutter={GUTTER}>
        <Grid.Column asListItem key="spacer-l" xs={2} xxs={12} />
        <Grid.Column
          asListItem
          key={`profile-document-${publications[0].share.identifier}`}
          xs={4}
          xxs={12}
        >
          <GridPublicationItem
            publication={publications[0]}
            url={
              isProfileDocumentViewFFEnabled
                ? routes.WORKSPACE_PROFILE_DOCUMENT.create({
                    shortUrlName: profile!.shortUrlName,
                    publicationId: publications[0].identifier,
                  })
                : routes.SHARE_VIEW.create({
                    shareID: publications[0].share.identifier,
                  })
            }
          />
        </Grid.Column>
        <Grid.Column
          asListItem
          key={`profile-document-${publications[1].share.identifier}`}
          xs={4}
          xxs={12}
        >
          <GridPublicationItem
            publication={publications[1]}
            url={
              isProfileDocumentViewFFEnabled
                ? routes.WORKSPACE_PROFILE_DOCUMENT.create({
                    shortUrlName: profile!.shortUrlName,
                    publicationId: publications[0].identifier,
                  })
                : routes.SHARE_VIEW.create({
                    shareID: publications[0].share.identifier,
                  })
            }
          />
        </Grid.Column>
        <Grid.Column asListItem key="spacer-r" xs={2} xxs={12} />
      </Grid>
    )
  }

  return (
    <Grid asList gutter={GUTTER}>
      {publications.map(publication => {
        const { identifier: publicationId, share } = publication

        /*
         * For single documents, we want to avoid HUGE thumbnails and keep the item centered.
         * We are setting column breakpoints specifically for this case.
         */
        if (isSingleDocument) {
          return (
            <React.Fragment key="spacer-l">
              <Grid.Column sm={4} xs={3} xxs={12} />
              <Grid.Column
                key={`profile-document-${share.identifier}`}
                sm={4}
                xs={6}
                xxs={12}
              >
                <GridPublicationItem
                  publication={publication}
                  url={
                    isProfileDocumentViewFFEnabled
                      ? routes.WORKSPACE_PROFILE_DOCUMENT.create({
                          shortUrlName: profile!.shortUrlName,
                          publicationId,
                        })
                      : routes.SHARE_VIEW.create({
                          shareID: share.identifier,
                        })
                  }
                />
              </Grid.Column>
              <Grid.Column sm={4} xs={3} xxs={12} />
            </React.Fragment>
          )
        }

        return (
          <Grid.Column
            asListItem
            key={`profile-document-${share.identifier}`}
            {...COLUMN_BREAKPOINTS}
          >
            <GridPublicationItem
              publication={publication}
              url={
                isProfileDocumentViewFFEnabled
                  ? routes.WORKSPACE_PROFILE_DOCUMENT.create({
                      shortUrlName: profile!.shortUrlName,
                      publicationId,
                    })
                  : routes.SHARE_VIEW.create({
                      shareID: share.identifier,
                    })
              }
            />
          </Grid.Column>
        )
      })}

      {placeholders.map((placeholder, placeholderIndex) => {
        const index = publications.length + placeholderIndex

        const columnSize = getGridColumnSize(index)

        return (
          <Grid.Column
            asListItem
            key={`placeholder-${index}-${columnSize}`}
            {...COLUMN_BREAKPOINTS}
          >
            {placeholder}
          </Grid.Column>
        )
      })}
    </Grid>
  )
}
