import { jsx, Fragment, jsxs } from 'react/jsx-runtime';
import { Route } from 'react-router-dom';
import { routes } from '@sketch/modules-common';
import './GeneralSettings/index.js';
import './AppearanceSettings/index.js';
import { PageWrapper } from './UserSettingsView.styles.js';
import GeneralSettings from './GeneralSettings/GeneralSettings.js';
import AppearanceSettings from './AppearanceSettings/AppearanceSettings.js';

const UserSettingsView = (props) => {
    const { workspace, useOverrideLayoutProps } = props;
    useOverrideLayoutProps({
        title: 'Settings',
    });
    return (jsx(Fragment, { children: jsxs(PageWrapper, { children: [jsx(Route, Object.assign({ exact: true, path: [
                        routes.PERSONAL_SETTINGS.template(),
                        routes.PERSONAL_SETTINGS_UNSUBSCRIBE.template(),
                    ] }, { children: jsx(GeneralSettings, { currentWorkspace: workspace }) })), jsx(Route, Object.assign({ exact: true, path: [routes.APPEARANCE_SETTINGS.template()] }, { children: jsx(AppearanceSettings, {}) }))] }) }));
};

export { UserSettingsView };
