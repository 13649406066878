import { useCallback, useState } from 'react'
import { SketchElement } from '../../../../../inspector'
import {
  useCanvasClickHandler,
  useCanvasMouseMoveHandler,
} from '../sketchSceneLib'
import { isUserInteractionEnabledOnSketchElement } from './isUserInteractionEnabledOnSketchElement'

/**
 * Create event handler and react states to display hover and selected elements state.
 */
export function useInspectorOverlayEvents(
  sketchSceneRootElement: SketchElement | null,
  /** This should only be enabled when the inspector is open and ready */
  isEnabled: boolean
) {
  const [selectedElement, setSelectedElement] = useState<SketchElement | null>(
    null
  )

  const [hoveredElement, setHoveredElement] = useState<SketchElement | null>(
    null
  )

  const { clickHandler, mouseDownHandle } = useCanvasClickHandler(
    sketchSceneRootElement,
    setSelectedElement,
    isUserInteractionEnabledOnSketchElement
  )

  const mouseMoveHandler = useCanvasMouseMoveHandler(
    isEnabled,
    sketchSceneRootElement,
    setHoveredElement,
    isUserInteractionEnabledOnSketchElement
  )

  const setSelectedElementExternal = useCallback(
    (selectedElement: SketchElement | null) => {
      setSelectedElement(selectedElement)
    },
    []
  )

  return {
    hoveredElement,
    selectedElement,
    /**
     * We need to also be able to set the selected element identifier from outside of events.
     * (using context menu for examples)
     */
    setSelectedElement: setSelectedElementExternal,
    clickHandler,
    mouseMoveHandler,
    mouseDownHandle,
  }
}
