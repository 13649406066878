import React, { useEffect } from 'react'
import { RouteComponentProps, Redirect } from 'react-router-dom'

import { GenericErrorView, routes, RouteParams } from '@sketch/modules-common'

import { WelcomeModalTrigger } from '../../components'
import { LoadingState } from '@sketch/components'
import { DiscoverNavBar, useDiscover } from '@sketch/discover'

import {
  useGetWorkspaceBillingQuery,
  useGetDiscoverQuery,
} from '@sketch/gql-types'
import { WorkspaceSidebarLayoutExtraProps } from '../../components/WorkspaceSidebarLayout/WorkspaceSidebarLayout'

import { Iframe } from './DiscoverView.styles'

export type DiscoverViewRoutesProps = RouteComponentProps<
  RouteParams<'WORKSPACE_DISCOVER'>
>

type DiscoverViewProps = DiscoverViewRoutesProps &
  WorkspaceSidebarLayoutExtraProps

/**
 * Discover View
 *
 * Renders the view for the Discover page
 * Route: /workspace/{workspaceId}/discover
 */
const DiscoverView = ({
  workspace,
  NavbarPortal,
  useOverrideLayoutProps,
}: DiscoverViewProps) => {
  useOverrideLayoutProps({
    title: 'Discover',

    /*
     * We are hiding the header per design given the discover view on the refreshUI
     * https://www.notion.so/sketch-hq/Remove-the-navigation-bar-from-Discover-643b1a6c2945495ebbbc2aa3e8ca7874?pvs=4
     */
    hideHeader: true,
  })

  // Sets discover content hash to local storage
  const { setViewedDiscover } = useDiscover()

  useEffect(() => {
    setViewedDiscover()
  }, [setViewedDiscover])

  const {
    data: discoverData,
    loading: discoverLoading,
    error: discoverError,
  } = useGetDiscoverQuery()

  const billingQuery = useGetWorkspaceBillingQuery({
    variables: { identifier: workspace.identifier },
    skip: !workspace?.customer?.identifier,
  })

  if (discoverLoading) {
    return <LoadingState />
  }

  if (discoverError || !discoverData) {
    return <GenericErrorView isInLayout />
  }

  const isFinance = workspace!.userRole === 'FINANCE'

  if (isFinance) {
    return <Redirect to={routes.ENTRY.create({})} />
  }

  const subscription = billingQuery.data?.workspace?.customer?.billing

  return (
    <>
      <NavbarPortal>
        <DiscoverNavBar />
      </NavbarPortal>

      <Iframe title="Sketch Discover" src="https://sketch.com/discover/" />

      {subscription && (
        <WelcomeModalTrigger
          subscriptionDetails={subscription}
          workspace={workspace}
        />
      )}
    </>
  )
}

export default DiscoverView
