import styled, { css } from 'styled-components';
import '../Segment/index.js';
import { Segment } from '../Segment/Segment.js';
import { disabledStyles } from '../Segment/Segment.styles.js';

const fieldsetReset = css `
  border: 0;
  padding: 0;
  margin: 0;
  min-width: 0;
`;
const Wrapper = styled.fieldset `
  ${fieldsetReset};

  display: inline-flex;
  flex-shrink: 0;
  line-height: 0;
  margin-right: 8px;
`;
const Input = styled.input `
  appearance: none;
  margin: 0;

  /* 
    The ".sr-only" class when applied is being ignored when the input is active
    per definition, this is a copy of the same style as ".sr-only" always applied
  */
  display: inline;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  &:disabled + ${Segment} {
    ${disabledStyles}
  }
`;

export { Input, Wrapper };
