import React, { useEffect, useState, useRef } from 'react'
import groupBy from 'lodash.groupby'
import uniqWith from 'lodash.uniqwith'
import partition from 'lodash.partition'
import { ExportFormat, VisibleScaleType } from '../../../../../../inspector'
import { ErrorHandler } from '@sketch/tracing'
import { ExportFormat as ExportFormatGQL } from '@sketch/gql-types/gqlTypes/typemapImports'

import {
  Spinner,
  Text,
  Tooltip,
  Popover,
  downloadFile,
} from '@sketch/components'

import { useHover, usePrevious } from '@sketch/utils'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import { ReactComponent as ExportAssetsIcon } from '@sketch/icons/arrow-down-to-line-16'

import {
  VersionFragment,
  useRenderDownloadableAssetsMutation,
} from '@sketch/gql-types'

import {
  ExportValue,
  ExportsSection,
  DownloadFormatButton,
  SectionStyled,
  QuestionMark,
  NoAssetsAvailableTitle,
  NoAssetsAvailableTooltip,
  Error,
  AllFormatsSeparator,
  ExportButtonWrapper,
} from './Export.styles'
import {
  Attribute,
  AttributeList,
  Header,
  Separator,
  HeaderTitle,
  AttributeLabel,
} from '../../components'

//-----------------------------------------------------------------------------
// UTILS

const isSVGExport = (xport: ExportFormat): boolean =>
  xport.fileFormat.toLowerCase() === 'svg'

const isAxisConstrainedExport = (xport: ExportFormat): boolean =>
  xport.visibleScaleType === VisibleScaleType.Width ||
  xport.visibleScaleType === VisibleScaleType.Height

const stringForExport = (xport: ExportFormat): string => {
  if (xport.fileFormat.toLowerCase() === 'svg') {
    return 'Scalable'
  }

  switch (xport.visibleScaleType) {
    case VisibleScaleType.Width:
      return `${xport.absoluteSize}w`

    case VisibleScaleType.Height:
      return `${xport.absoluteSize}h`

    case VisibleScaleType.Scale:
      return `${xport.scale}x`
  }
}

const stringForExports = (exports: ExportFormat[]): string => {
  const [axisConstrainedExports, scaledExports] = partition(
    uniqWith(exports, isSVGExport),
    isAxisConstrainedExport
  )

  const axisStrings = axisConstrainedExports
    .sort((a, b) => a.absoluteSize - b.absoluteSize)
    .sort((a, b) => a.visibleScaleType - b.visibleScaleType)

  const scaledStrings = scaledExports.sort((a, b) => a.scale - b.scale)

  return [...scaledStrings, ...axisStrings].map(stringForExport).join(', ')
}

type ErrorType = 'default' | 'forbidden' | undefined

interface IndividualAssetsProps {
  layerOrArtboardId: string
  formats?: ExportFormat['fileFormat'][]
  scales?: number[]
  currentVersion: VersionFragment
}

// React hook to manage the logic to request individual assets
const useRequestIndividualAssets = ({
  layerOrArtboardId,
  formats = [],
  scales = [],
  currentVersion,
}: IndividualAssetsProps) => {
  // Mutation to request individual assets, for now this process is
  // synchronously for layer assets so it will return the asset ready to download
  const [
    renderAssets,
    { data, loading, error },
  ] = useRenderDownloadableAssetsMutation({
    redirectErrors: false,
    variables: {
      documentIdentifier: currentVersion.document?.identifier || '',
      input: {
        layerUuids: [layerOrArtboardId],
        // TODO: Remove "as" https://github.com/sketch-hq/Cloud/issues/19018
        formats: formats as ExportFormatGQL[],
        scales,
      },
    },
    onError: 'unsafe-throw-exception',
  })

  const shouldShowError =
    (error || data?.renderDownloadableAssets?.errors.length) && !loading

  const errorType: ErrorType = shouldShowError
    ? (data?.renderDownloadableAssets?.errors || []).find(
        error => error?.code === 'FORBIDDEN'
      )
      ? 'forbidden'
      : 'default'
    : undefined

  const asset = data?.renderDownloadableAssets?.downloadableAsset
  const previousAsset = usePrevious(asset)

  // Automatically downloads the asset when it's ready
  useEffect(() => {
    if (asset?.path && !previousAsset && !shouldShowError) {
      downloadFile(asset!.path!)
    }
  }, [asset, previousAsset, shouldShowError])

  return { renderAssets, loading, errorType }
}

//-----------------------------------------------------------------------------
// COMPONENTS:
//  - ErrorText: shows different types of errors when downloading an asset
//  - ExportButton: button to download an individual asset
//  - Export: download info for every format (title, value and button)
//  - ExportAll: same as Export but to export all formats from an individual asset
//  - MakeExportable: info with tooltip shown when a layer has no assets exported
//  - Exports: exported component that builds the rest of the pieces

interface ErrorTextProps {
  type: ErrorType
}

const ErrorText: React.FC<ErrorTextProps> = ({ type = 'default' }) => {
  const text = {
    default:
      'Failed to export assets. Something went wrong. Try uploading the document again',
    forbidden:
      'The option to export assets has been disabled in this document’s settings. Please contact the document’s owner for assistance.',
  }

  return (
    <Text color="red" marginBottom={0} textAlign="center">
      {text[type]}
    </Text>
  )
}

interface ExportButtonProps {
  loading: boolean
  onClick: () => void
}

const ExportButton: React.FC<ExportButtonProps> = ({
  onClick,
  loading,
  ...props
}) => (
  <>
    <DownloadFormatButton
      disabled={loading}
      onClick={() => !loading && onClick()}
      data-testid="download-asset-request"
      {...props}
    >
      {loading ? (
        <Spinner primary size="12px" />
      ) : (
        <ExportAssetsIcon width={17} height={17} color="grey" />
      )}
    </DownloadFormatButton>
  </>
)

interface ExportProps
  extends Pick<IndividualAssetsProps, 'layerOrArtboardId' | 'currentVersion'> {
  format: ExportFormat['fileFormat']
  exportByName: ExportFormat[]
}

const Export = ({
  format,
  exportByName,
  currentVersion,
  layerOrArtboardId,
}: ExportProps) => {
  const { renderAssets, loading, errorType } = useRequestIndividualAssets({
    layerOrArtboardId: layerOrArtboardId,
    formats: [format.toUpperCase()] as ExportFormat['fileFormat'][],
    scales: exportByName
      ? // Note: this is just simple way to keep unique items: `Array.from(new Set([1,2,2]))` returns [1,2]
        Array.from(new Set(exportByName.map(e => e.scale)))
      : [],
    currentVersion,
  })

  const Wrapper = errorType ? Error : React.Fragment

  return (
    <Wrapper>
      <Attribute key={format}>
        <AttributeLabel>{format.toUpperCase()}</AttributeLabel>
        <ExportValue>{stringForExports(exportByName)}</ExportValue>
        <ExportButtonWrapper>
          <ExportButton onClick={renderAssets} loading={loading} />
        </ExportButtonWrapper>
        {errorType && <ErrorText type={errorType} />}
      </Attribute>
    </Wrapper>
  )
}

const ExportAll = ({
  currentVersion,
  layerOrArtboardId,
}: Pick<IndividualAssetsProps, 'layerOrArtboardId' | 'currentVersion'>) => {
  const { renderAssets, loading, errorType } = useRequestIndividualAssets({
    layerOrArtboardId,
    currentVersion,
  })

  const Wrapper = errorType ? Error : React.Fragment

  return (
    <Wrapper>
      <Attribute>
        <AttributeLabel>
          <strong>All Formats</strong>
        </AttributeLabel>
        <ExportValue />
        <ExportButtonWrapper>
          <Tooltip content="Download all available formats for this layer">
            <ExportButton
              onClick={renderAssets}
              loading={loading}
              data-testid="download-asset-all-request"
            />
          </Tooltip>
        </ExportButtonWrapper>
        {errorType && <ErrorText type={errorType} />}
      </Attribute>
    </Wrapper>
  )
}

const exportTypeName = {
  layer: 'layer',
  artboard: 'Artboard',
}

interface NoAssetsAvailableProps {
  exportType: 'layer' | 'artboard'
}
const NoAssetsAvailable = ({ exportType }: NoAssetsAvailableProps) => {
  const [hovered, hoverEventListeners] = useHover()

  // We need more control to show or hide the tooltip, because a small
  // delay is needed to close it (to let the user reach the tooltip from the trigger)
  const [visible, setVisibility] = useState(false)

  // This ref helps to avoid closing the tooltip if the user is over it
  const shouldIgnoreClose = useRef(false)

  useEffect(() => {
    if (!hovered) {
      // transitioning from visible to hidden
      setTimeout(() => {
        if (!shouldIgnoreClose.current) setVisibility(false)
      }, 100)
    } else {
      setVisibility(true)
    }
  }, [hovered])

  return (
    <SectionStyled>
      <Header>
        <Popover
          spacing="18px"
          style={{
            height: '18px',
          }}
          visible={visible}
          popup={
            <NoAssetsAvailableTooltip
              onMouseEnter={() => (shouldIgnoreClose.current = true)}
              onMouseLeave={() => {
                shouldIgnoreClose.current = false
                setVisibility(false)
              }}
            >
              To download assets, first make this {exportTypeName[exportType]}{' '}
              exportable in the Mac app.{' '}
              <a
                href={`${SKETCH_WEBSITE}/docs/exporting/#how-to-create-and-use-export-presets`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </a>
            </NoAssetsAvailableTooltip>
          }
        >
          <NoAssetsAvailableTitle {...hoverEventListeners}>
            No assets available <QuestionMark />
          </NoAssetsAvailableTitle>
        </Popover>
      </Header>
    </SectionStyled>
  )
}

export interface ExportsProps {
  exportType: 'artboard' | 'layer'
  exportFormats: ExportFormat[]
  currentVersion: VersionFragment
  hasOtherExportableLayers?: boolean
  exportableAssetId?: string
  nodeIdentifier: bigint
  elementName: string
}

export const Exports = ({
  exportType,
  exportFormats,
  currentVersion,
  exportableAssetId,
  hasOtherExportableLayers = false,
}: ExportsProps) => {
  const hasExports = Boolean(exportFormats.length)
  // We can have multiple export using the same file format but different scales, let's group them.
  const exportByFileFormat = groupBy(exportFormats, 'fileFormat')
  // Cast because TS Object.keys looses the specific type
  const availableFileFormatNames = Object.keys(exportByFileFormat) as Array<
    ExportFormat['fileFormat']
  >

  // The selected export does not have any export but there are
  // other layers in the artboard with some exportable assets
  // and we prefer to not show the "No assets available" message to avoid
  // confusion.
  if (!hasExports && hasOtherExportableLayers) {
    return null
  }

  // The selected export does not have any export and there
  // are no other layers in the artboard with some exportable assets
  // (except maybe the artboard itself which is not counted here)
  // Here we show the "No assets available" message.
  if (!hasExports && !hasOtherExportableLayers) {
    return (
      <>
        <Separator />
        <NoAssetsAvailable exportType={exportType} />
      </>
    )
  }

  if (!exportableAssetId) {
    ErrorHandler.shouldNeverHappen(
      'We expect exportableAssetId to always be present if there are export formats'
    )
    return null
  }

  return (
    <>
      <Separator />
      <ExportsSection data-testid="inspector-sidebar-exports">
        <Header>
          <HeaderTitle>Assets</HeaderTitle>
        </Header>
        <AttributeList>
          {availableFileFormatNames.map(format => (
            <Export
              key={format}
              format={format}
              exportByName={exportByFileFormat[format]}
              layerOrArtboardId={exportableAssetId}
              currentVersion={currentVersion}
            />
          ))}
          {availableFileFormatNames.length > 1 && (
            <>
              <AllFormatsSeparator />
              <ExportAll
                currentVersion={currentVersion}
                layerOrArtboardId={exportableAssetId}
              />
            </>
          )}
        </AttributeList>
      </ExportsSection>
    </>
  )
}
