import styled from 'styled-components';
import { Link } from '@sketch/components';

const Wrapper = styled.div `
  max-width: 400px;
  width: 100%;
`;
const SsoInformationAndFaqWrapper = styled.section `
  display: flex;
  flex-direction: column;
`;
const StyledLink = styled(Link) `
  display: block;
  margin-top: 24px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.E};

  & + & {
    margin-top: 12px;
  }
`;
const Text = styled.p `
  margin-top: 32px;
  font-size: ${({ theme }) => theme.fontSizes.E};
  text-align: center;
`;
const Line = styled.div `
  width: 100%;
  margin-top: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`;

export { Line, SsoInformationAndFaqWrapper, StyledLink, Text, Wrapper };
