import { __awaiter } from 'tslib';
import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useMemo } from 'react';
import { Route } from 'react-router';
import styled from 'styled-components';
import { dataIdFromObject } from '@sketch/graphql-cache';
import { MountHandle } from '@sketch/utils';
import './WorkspaceList/index.js';
import './SettingsForm/index.js';
import { TwoFactorAuthenticationPanel, EmailNotificationsPanel } from '@sketch/user';
import './SessionsManagementPanel/index.js';
import { useModalContext, handleFetchMore, Section, LinkButton } from '@sketch/components';
import './OtherOptionsPanel/index.js';
import './LinkNonSSO/index.js';
import './LeaveWorkspaceModal/index.js';
import { useUserProfile, useUserAuthorizations, getIsForInAppPresentation, isSsoAuthorization, getPersonalAuthorization, routes } from '@sketch/modules-common';
import { useToast } from '@sketch/toasts';
import { useUserUpdateMutation, useGetUserSettingsQuery } from '@sketch/gql-types';
import { LinkNonSSO } from './LinkNonSSO/LinkNonSSO.js';
import SettingsForm from './SettingsForm/SettingsForm.js';
import WorkspaceList from './WorkspaceList/WorkspaceList.js';
import LeaveWorkspaceModal from './LeaveWorkspaceModal/LeaveWorkspaceModal.js';
import SessionsManagementPanel from './SessionsManagementPanel/SessionsManagementPanel.js';
import { OtherOptionsPanel } from './OtherOptionsPanel/OtherOptionsPanel.js';

const createOptimisticResponse = (user, receiveEmailNotifications) => ({
    __typename: 'RootMutationType',
    userUpdate: {
        __typename: 'UserUpdatePayload',
        errors: [],
        successful: true,
        user: Object.assign(Object.assign({}, user), { receiveEmailNotifications }),
    },
});
const RelativeContainer = styled.div `
  position: relative;
`;
const GeneralSettings = ({ currentWorkspace }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { showModal } = useModalContext();
    const { showToast } = useToast();
    const { data: userData } = useUserProfile();
    const { authorizations, hasPersonalAuthorization } = useUserAuthorizations();
    const isForInAppPresentation = getIsForInAppPresentation();
    const [updateUser, { called }] = useUserUpdateMutation({
        redirectErrors: false,
        onCompleted: data => {
            const { errors, successful } = data.userUpdate || {};
            if (successful) {
                showToast('Profile settings updated');
            }
            if ((errors === null || errors === void 0 ? void 0 : errors.length) > 0) {
                const errorMessage = errors.map(error => error === null || error === void 0 ? void 0 : error.message).join('; ');
                showToast(errorMessage, 'negative');
            }
        },
        onError: error => {
            showToast(error.message, 'negative');
        },
    });
    const { data: userSettingsData, fetchMore } = useGetUserSettingsQuery();
    const handleLoadMore = handleFetchMore(fetchMore, ['me', 'workspaceMemberships', 'entries'], {
        after: (_b = (_a = userSettingsData === null || userSettingsData === void 0 ? void 0 : userSettingsData.me) === null || _a === void 0 ? void 0 : _a.workspaceMemberships) === null || _b === void 0 ? void 0 : _b.meta.after,
        dataIdFromObject,
    });
    // Merge the user objects, more precisely get the "newEmail".
    const user = Object.assign(Object.assign({}, userData.me), userSettingsData === null || userSettingsData === void 0 ? void 0 : userSettingsData.me);
    const memberships = ((_d = (_c = userSettingsData === null || userSettingsData === void 0 ? void 0 : userSettingsData.me) === null || _c === void 0 ? void 0 : _c.workspaceMemberships) === null || _d === void 0 ? void 0 : _d.entries) || [];
    const hasMASubscription = memberships.some(membership => {
        var _a, _b, _c;
        return ((_b = (_a = membership === null || membership === void 0 ? void 0 : membership.workspace) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.source) === 'macappstore' &&
            ((_c = membership === null || membership === void 0 ? void 0 : membership.workspace) === null || _c === void 0 ? void 0 : _c.userIsOwner);
    });
    const isSignedInWithSso = authorizations.some(isSsoAuthorization);
    const isSsoOnly = !getPersonalAuthorization();
    const workspaceName = ((_h = (_g = (_f = (_e = userSettingsData === null || userSettingsData === void 0 ? void 0 : userSettingsData.me) === null || _e === void 0 ? void 0 : _e.workspaceMemberships) === null || _f === void 0 ? void 0 : _f.entries[0]) === null || _g === void 0 ? void 0 : _g.workspace) === null || _h === void 0 ? void 0 : _h.name) ||
        '';
    const SSOWorkspace = useMemo(() => {
        var _a, _b;
        return (_b = (_a = userSettingsData === null || userSettingsData === void 0 ? void 0 : userSettingsData.me) === null || _a === void 0 ? void 0 : _a.workspaceMemberships) === null || _b === void 0 ? void 0 : _b.entries.find(membership => { var _a; return (_a = membership.workspace.customer) === null || _a === void 0 ? void 0 : _a.ssoEnabled; });
    }, [userSettingsData]);
    const showLinkNonSso = !!SSOWorkspace && (isSsoOnly || (!isSsoOnly && user.hasPersonalIdentity));
    // Show the subscribe to email comments IF the user has personal identity OR it's a business workspace
    const showEmailNotificationsPanel = user.hasPersonalIdentity || (currentWorkspace === null || currentWorkspace === void 0 ? void 0 : currentWorkspace.type) === 'BUSINESS';
    return (jsxs(Fragment, { children: [jsx(Route, { path: routes.PERSONAL_SETTINGS_UNSUBSCRIBE.template(), render: ({ history }) => (jsx(MountHandle, { onMount: () => __awaiter(void 0, void 0, void 0, function* () {
                        // Prevent calling the mutation again if it's already called (when mounting)
                        // Related to https://github.com/sketch-hq/Cloud/issues/6102#issuecomment-804823199
                        if (called) {
                            return;
                        }
                        user.hasPersonalIdentity &&
                            (yield updateUser({
                                variables: { input: { receiveEmailNotifications: false } },
                                optimisticResponse: createOptimisticResponse(user, false),
                            }));
                        history.replace(routes.PERSONAL_SETTINGS.create({}));
                    }) })) }), jsx(RelativeContainer, { children: jsxs(Section, Object.assign({ title: "Your Account Settings" }, { children: [showLinkNonSso && currentWorkspace && (jsx(LinkNonSSO, { linkedEmail: user.personalIdentityEmail, workspaceName: workspaceName, currentWorkspace: currentWorkspace, SSOWorkspace: SSOWorkspace })), jsx(SettingsForm, { userName: user.name, userEmail: user.email, userNewEmail: user.newEmail, userAvatarSrc: ((_j = user.avatar) === null || _j === void 0 ? void 0 : _j.large) || user.avatar.small, userHasAvatar: user.hasAvatar, userHasPersonalIdentity: user.hasPersonalIdentity, userHasPersonalAuthorization: hasPersonalAuthorization, isSignedInWithSso: isSignedInWithSso, onUserUpdate: updateUser })] })) }), !isSsoOnly && (jsx(TwoFactorAuthenticationPanel, { mfaState: user.mfaState, isVerified: user.isVerified })), memberships.length > 0 && (jsx(WorkspaceList, { memberships: memberships, onLoadMore: handleLoadMore, onLeaveWorkspace: membership => {
                    showModal(LeaveWorkspaceModal, {
                        membershipId: membership.identifier,
                        workspace: membership.workspace,
                        userHasPersonalIdentity: user.hasPersonalIdentity,
                    });
                }, linkButton: hasPersonalAuthorization &&
                    !hasMASubscription &&
                    !isForInAppPresentation && (jsx(LinkButton, Object.assign({ to: routes.WORKSPACE_CREATE.create({}), variant: "primary", size: "40" }, { children: "Create Workspace\u2026" }))) })), jsx(SessionsManagementPanel, {}), showEmailNotificationsPanel && (jsx(EmailNotificationsPanel, { receiveEmailNotifications: user.receiveEmailNotifications })), jsx(OtherOptionsPanel, { hasPersonalIdentity: user.hasPersonalIdentity })] }));
};

export { GeneralSettings as default };
