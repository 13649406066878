import {
  useRevisionCreatedSubscription,
  useRevisionDeletedSubscription,
  useRevisionMovedSubscription,
  RevisionDeletedFragment,
  RevisionInfoFragment,
} from '@sketch/gql-types'
import { ErrorHandler } from '@sketch/tracing'
import { updateArtboardRevisions } from '../../versioning/operations/updateArtboardRevisions'

const SUBSCRIPTIONS_SHOULD_BE_VALID = (name: string) =>
  `Received "${name}" subscription data should always be valid one`

export interface RevisionMovedProps {
  type: 'revision-moved'
  revision: RevisionInfoFragment
  selectedRevisionChange: boolean
}

export interface RevisionDeletedProps {
  type: 'revision-deleted'
  revision: RevisionDeletedFragment
  selectedRevisionChange: boolean
}

export interface RevisionCreatedProps {
  type: 'revision-created'
  revision: RevisionInfoFragment
  selectedRevisionChange: false
}

export type RevisionChangedProps =
  | RevisionMovedProps
  | RevisionDeletedProps
  | RevisionCreatedProps

export interface UseRevisionSubscriptionsProps {
  artboard: {
    permanentArtboardShortId: string
    identifier: string
    uuid: string
  }
  share: {
    identifier: string
  }
  onRevisionChange: (props: RevisionChangedProps) => void
}

export const useRevisionSubscriptions = ({
  artboard: { permanentArtboardShortId, identifier: artboardIdentifier, uuid },
  share,
  onRevisionChange,
}: UseRevisionSubscriptionsProps) => {
  const variables = {
    permanentArtboardShortId,
    includeArtboardDetails: true,
  }

  // New created revisions can appear only as the most recent activity event
  // therefore we just need to add it the beginning of the list
  useRevisionCreatedSubscription({
    variables,
    onSubscriptionData: ({
      client,
      subscriptionData: { loading, data, error },
    }) => {
      if (!data || error || loading) {
        ErrorHandler.shouldNeverHappen(
          SUBSCRIPTIONS_SHOULD_BE_VALID('revisionCreated')
        )
        return
      }

      onRevisionChange({
        type: 'revision-created',
        selectedRevisionChange: false,
        revision: data.revisionCreated,
      })

      updateArtboardRevisions({
        client,
        variables: {
          permanentArtboardIdentifier: uuid,
          shareIdentifier: share.identifier,
        },
        update: artboardRevisions => {
          const { order } = artboardRevisions.entries[0] || {}
          artboardRevisions.entries.unshift({
            ...data.revisionCreated,
            order: (order || 0) + 1,
          })

          artboardRevisions.meta.totalCount++
        },
      })
    },
  })

  useRevisionMovedSubscription({
    variables,
    onSubscriptionData: ({ subscriptionData: { loading, data, error } }) => {
      if (!data || error || loading) {
        ErrorHandler.shouldNeverHappen(
          SUBSCRIPTIONS_SHOULD_BE_VALID('revisionMoved')
        )
        return
      }

      const { previousArtboardIdentifier } = data.revisionMoved
      const selectedRevisionChange =
        artboardIdentifier === previousArtboardIdentifier

      onRevisionChange({
        type: 'revision-moved',
        selectedRevisionChange,
        revision: data.revisionMoved.revision,
      })
    },
  })

  useRevisionDeletedSubscription({
    variables: { permanentArtboardShortId },
    onSubscriptionData: ({
      client,
      subscriptionData: { loading, data, error },
    }) => {
      if (!data || error || loading) {
        ErrorHandler.shouldNeverHappen(
          SUBSCRIPTIONS_SHOULD_BE_VALID('revisionDeleted')
        )
        return
      }
      const previousArtboardIdentifier = data.revisionDeleted.artboardIdentifier
      const selectedRevisionChange =
        artboardIdentifier === previousArtboardIdentifier

      onRevisionChange({
        type: 'revision-deleted',
        selectedRevisionChange,
        revision: data.revisionDeleted,
      })
    },
    fetchPolicy: 'no-cache',
  })
}
