import styled, { css } from 'styled-components';
import { Link, Text } from '@sketch/components';
import { breakpoint } from '@sketch/global-styles';

const StyledLink = styled(Link) `
  display: block;
  margin-top: 24px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.E};
`;
const FormContainer = styled.div `
  max-width: 400px;
  margin: 0 auto;
`;
const DimText = styled(Text).attrs({
    textStyle: 'copy.quaternary.standard.E',
}) `
  max-width: 400px;
  margin: 24px auto 0;
  text-align: center;

  ${StyledLink} {
    display: inline-block;
    margin: 0;
    color: ${({ theme }) => theme.colors.foreground.secondary.D};
  }
`;
const TextSignUp = styled.p `
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  margin-top: 32px;
  font-size: ${({ theme }) => theme.fontSizes.E};
`;
const Line = styled.div `
  width: 100%;
  margin-top: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.A};
`;
const Container = styled.div `
  width: auto;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  padding: 0;

  ${breakpoint('md') `
    padding: 0 32px;
    max-width: 600px;
  `}
`;
const Title = styled(Text.H1) `
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  margin: 0 0 16px 0;
  font-size: ${({ theme }) => theme.fontSizes.I};
  line-height: 1;
`;
const Subtitle = styled(Text.P) `
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  margin-bottom: 24px;
`;
const Feature = styled.div `
  color: ${({ theme }) => theme.colors.foreground.secondary.A};
  display: flex;
  align-items: top;
  margin-bottom: 12px;
  line-height: 1.4;
`;
const FeatureImg = styled.img `
  margin-right: 8px;
  width: 24px;
  height: 24px;

  // Invert the icon depending on the Dark Mode setting
  ${({ isDarkModeActive }) => isDarkModeActive
    ? css `
          filter: saturate(0) invert(1);
        `
    : null}
`;
const OperatingSystemWarning = styled(Text).attrs({
    textStyle: 'copy.quaternary.standard.C',
}) `
  text-align: center;
  margin-top: 12px;
`;
const RequiredOSVersion = styled.div `
  margin-bottom: 24px;
  padding: 16px;
  font-size: ${({ theme }) => theme.fontSizes.C};
  color: ${({ theme }) => theme.colors.foreground.secondary.C};
  background-color: ${({ theme }) => theme.colors.background.tertiary.C};
  border-radius: ${({ theme }) => theme.radii.large};
`;

export { Container, DimText, Feature, FeatureImg, FormContainer, Line, OperatingSystemWarning, RequiredOSVersion, StyledLink, Subtitle, TextSignUp, Title };
