import React from 'react'

import {
  Button,
  Skeleton,
  pluralize,
  Separator,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  ModalInjectedProps,
} from '@sketch/components'
import { UserRow } from '../../components/UserRow'
import { getMemberName } from '../../utils'
import { SharePermissionsTab } from './SharePermissionsTab'
import { ProjectPermissionsTab } from './ProjectPermissionsTab'
import { GroupPermissionsTab } from './GroupPermissionsTab'
import { useManageUserPermissions } from './useManageUserPermissions'
import { StyledModal as Modal } from './ManageUserPermissionsModal.styles'

import { WorkspaceMembershipFragment } from '@sketch/gql-types'
import { useUserProfile } from '@sketch/modules-common'

interface ManageUserPermissionsModalProps extends ModalInjectedProps {
  workspaceId: string
  member: WorkspaceMembershipFragment
  showWorkspacePermissionGroup?: boolean
}

const MAX_NUMBER_ROWS = 8
/*
 * ManageUserPermissions
 *
 * Renders a modal that allows the user to
 * manage the permissions
 */
export const ManageUserPermissions: React.FC<ManageUserPermissionsModalProps> = props => {
  const {
    hideModal,
    member,
    workspaceId,
    showWorkspacePermissionGroup = false,
  } = props

  const { shares, projects, permissionGroups } = useManageUserPermissions({
    workspaceIdentifier: workspaceId,
    workspaceMembershipIdentifier: member.identifier,
    fetchPolicy: 'cache-and-network',
    fetchPermissionGroup: showWorkspacePermissionGroup,
  })

  const { data } = useUserProfile(true)
  const isCurrentUser = data?.me.identifier === member.user?.identifier
  // We need to set a minimum number of rows because
  // the empty state has a height that we need to keep
  // in mind
  const rows =
    shares.loading && projects.loading
      ? 0
      : Math.min(
          Math.max(shares.total || 0, projects.total || 0),
          MAX_NUMBER_ROWS
        )

  return (
    <Modal onCancel={hideModal} data-testid="user-permissions-modal">
      <Modal.Body>
        <UserRow
          identifier={member?.user?.identifier}
          name={getMemberName(member) || ''}
          email={member?.user?.email || ''}
          avatarSrc={member?.user?.avatar?.small}
          role={member?.role}
          isCurrentUser={isCurrentUser}
        />
        <Separator mt={3} mb={6} />
        <Tabs>
          <TabList>
            <Tab>
              {shares.loading && !shares.total ? (
                <Skeleton height="10px" width="20px" />
              ) : (
                `${shares.total} ${pluralize(
                  'Document',
                  'Documents',
                  shares.total || 0
                )}`
              )}
            </Tab>

            <Tab>
              {projects.loading && !projects.total ? (
                <Skeleton height="10px" width="20px" />
              ) : (
                `${projects.total} ${pluralize(
                  'Project',
                  'Projects',
                  projects.total || 0
                )}`
              )}
            </Tab>

            {showWorkspacePermissionGroup ? (
              <Tab data-testid="permission-group-user-tab">
                {permissionGroups.loading && !permissionGroups.total ? (
                  <Skeleton height="10px" width="20px" />
                ) : (
                  `${permissionGroups.total} ${pluralize(
                    'Group',
                    'Groups',
                    permissionGroups.total || 0
                  )}`
                )}
              </Tab>
            ) : (
              <React.Fragment />
            )}
          </TabList>

          <TabPanel>
            <SharePermissionsTab
              workspaceIdentifier={workspaceId}
              workspaceMembershipIdentifier={member.identifier}
              rows={rows}
            />
          </TabPanel>

          <TabPanel>
            <ProjectPermissionsTab
              workspaceIdentifier={workspaceId}
              workspaceMembershipIdentifier={member.identifier}
              rows={rows}
            />
          </TabPanel>

          {showWorkspacePermissionGroup && (
            <TabPanel>
              <GroupPermissionsTab
                workspaceIdentifier={workspaceId}
                workspaceMembershipIdentifier={member.identifier}
                rows={rows}
              />
            </TabPanel>
          )}
        </Tabs>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={hideModal} variant="secondary">
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
