import styled, { css } from 'styled-components';
import '../PageLayout/index.js';
import { breakpoint } from '@sketch/global-styles';
import { PageLayout as PageLayout$1 } from '../PageLayout/PageLayout.js';

const PageLayout = styled(PageLayout$1)(({ darkBackground, theme }) => css `
    /* Force this container to have scroll so the sticky objects can be topped correctly */
    flex: 1;
    overflow: auto;

    /* Force the sidebar placeholder to exist next to the content */
    flex-direction: row;
    background-color: ${theme.colors.background.secondary.B};

    & ${RightSidebarContainer} {
      background-color: ${!darkBackground
    ? theme.colors.background.secondary.B
    : theme.colors.background.secondary.A};
    }
  `);
const RightSidebarContainer = styled.div(({ theme, $sidebarOnTopOfHeader }) => css `
    display: flex;

    position: fixed;
    top: ${$sidebarOnTopOfHeader ? 0 : theme.header.height}px;
    right: 0;
    bottom: 0;

    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    transition-property: transform, box-shadow;
    transform: translate(100%, 0);

    box-shadow: 0px 0px 0px 0px transparent;

    z-index: ${$sidebarOnTopOfHeader
    ? theme.zIndex[5] /* on top of header and backdrop */
    : theme.zIndex[3]};
    overflow: hidden;

    &[aria-hidden='false'] {
      box-shadow: 0px 0px 0px 1px ${theme.colors.border.A}; /* Emulates a left border */
      transform: translate(0, 0);
    }

    ${breakpoint('base', 'sm') `
      /* We moved to position:fixed here because safari is showing the sidebar even when it's overflow:hidden */
      position: fixed;

      /* In mobile we force the top to be 0 because we want the sidebar to still cover the header */
      top: 0;
      z-index: ${theme.zIndex[5]};

      &[aria-hidden='false'] {
        box-shadow: 0px 0px 6px 1px ${theme.colors.border.A};
      }
    `}
  `);
const SidebarLeftContainer = styled.aside(({ theme, isSidebarLeftOpen }) => css `
    width: ${theme.sidebar.leftWidth}px;

    position: fixed;
    top: 0;
    bottom: 0;
    z-index: ${theme.zIndex[4]}; /* bigger z-index than header */

    display: flex;
    background-color: ${theme.colors.background.secondary.A};

    box-shadow: ${theme.shadows.boxShadow.elevated};

    will-change: transform; /* Force hardware acceleration */
    transform: ${isSidebarLeftOpen ? 'translateX(0)' : 'translateX(-100%)'};

    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    transition-property: transform, opacity;

    opacity: ${({ isSidebarLeftOpen }) => isSidebarLeftOpen ? 1 : 0};
  `);
/**
 * This component will allow the sidebar to track when we click outside of it
 * on mobile, to close it. This is because the web renderer doesn't bubble up the
 * events so useOnClickOutside won't know that we clicked outside of the sidebar
 *
 * It will be only used on mobile
 */
const SidebarLeftContainerBackdrop = styled.div(({ theme }) => css `
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: ${theme.zIndex[3]}; /* smaller z-index than sidebar */
  `);
/**
 * This component will allow the sidebar to be inline with the content,
 * to keep it's size.
 *
 * It will allow the animation to run smoother because it's more simpler
 * to draw
 *
 * It's only used in desktop breakpoint
 */
const SidebarPlaceholder = styled.div `
  width: 0;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
  transition-property: width;
  will-change: width;
`;
/**
 * This component will show in mobile when the right-sidebar is opened,
 * to allow the user to click on it to close the right-sidebar
 *
 * This component is only rendered in mobile Look at SidebarLayout.tsx
 */
const SidebarBackdrop = styled.div `
  position: fixed;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  z-index: ${({ theme }) => theme.zIndex[4]};

  background: ${({ theme }) => theme.colors.background.tertiary.A};

  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
  transition-property: background, visibility;

  ${RightSidebarContainer}[aria-hidden="true"] + & {
    background: none;
    /* This forces the backdrop to be hidden/actionable when the sidebar is hidden */
    visibility: hidden;
  }
`;
const ContentContainer = styled.div.attrs({
    'data-content-scrollable': true,
}) `
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
  overflow: auto;

  /* This div gets focus when the <PageLayout /> mounts because is the one
    with scroll, and focusing on this element gives the user the ability to use
    keyboard to scroll up/down, but we don't want any focus style */
  outline: none;
`;

export { ContentContainer, PageLayout, RightSidebarContainer, SidebarBackdrop, SidebarLeftContainer, SidebarLeftContainerBackdrop, SidebarPlaceholder };
