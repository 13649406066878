import React, { useImperativeHandle, useRef } from 'react'
import { useTheme } from 'styled-components'

import { useCanvasPresentationFile } from '../../../../../components/WebRendererCanvas/hooks'

import LoadingState from '../../../components/LoadingState'
import { PageCanvasError } from '../PageCanvasError'
import { WebRendererCanvas } from '../../../../../components/WebRendererCanvas'

import {
  useInitialCameraPosition,
  useSaveLastCameraPositionInLocalStorage,
} from '../../hooks'

import CanvasContextMenu, { useCanvasContextMenu } from '../CanvasContextMenu'
import {
  ArtboardsInfoOverlay,
  OriginContent,
  AnnotationsOverlay,
} from '../../../../canvas/components'
import { useCanvasAnnotations } from '../../../../annotations/hooks'
import {
  ArtboardDetailInspectorOverlay,
  useInspectorContext,
  useInspectorHoveredElement,
} from '../../../ArtboardDetailView'
import { ArtboardDetailInspectorContextMenu } from '../../../ArtboardDetailView/components/ArtboardDetailInspectorContextMenu'

import { PageCanvasWrapper, StyledPageCanvas } from './SharePageCanvas.styles'
import { getWebRenderCursor } from '../../../../inspector'
import { useObjectProxyAccumulator } from '@sketch/utils'
import { DocumentHead } from '@sketch/components'

interface SharePageCanvasProps {
  permanentPageIdentifier: string
}

export const SharePageCanvas = React.forwardRef<
  HTMLDivElement,
  SharePageCanvasProps
>(function SharePageCanvas({ permanentPageIdentifier: pageUUID }, ref) {
  const theme = useTheme()
  const wrapperRef = useRef<HTMLDivElement>(null)

  const {
    share,
    page,
    versionShortId,
    hasError,
    isLoading,
    isRendering,
  } = useCanvasPresentationFile({
    pageUUID,
  })

  const filePath = page?.presentationFile?.downloadUrl

  // Camera Position
  const initialCameraPosition = useInitialCameraPosition({
    pageUUID,
    versionShortId,
  })

  const { initialZoom, initialPan } = initialCameraPosition

  useSaveLastCameraPositionInLocalStorage({ pageUUID, versionShortId })

  // Context Menu
  const {
    contextMenuPos,
    contextMenuMetaData,
    canvasContainerRef,
    handleContextMenu,
    handleCloseContextMenu,
  } = useCanvasContextMenu()

  // Annotations
  const {
    activeAnnotation,
    isPlacingDraftAnnotation,
    ...annotationPointerEvents
  } = useCanvasAnnotations()

  const {
    isInspectorActive,
    clickHandler,
    mouseMoveHandler,
    mouseDownHandle,
  } = useInspectorContext()

  const hoveredElement = useInspectorHoveredElement()

  const webRenderContainerProps = useObjectProxyAccumulator(
    { onContextMenu: handleContextMenu },
    isInspectorActive
      ? {
          onClick: clickHandler,
          onMouseMove: mouseMoveHandler,
          onMouseDown: mouseDownHandle,
        }
      : annotationPointerEvents
  )

  useImperativeHandle(ref, () => wrapperRef.current!)
  useImperativeHandle(canvasContainerRef, () => wrapperRef.current!)

  if (isLoading || isRendering) {
    return <LoadingState />
  }

  /**
   * By this point filePath shouldn't ever be undefined, but we narrow the type
   * to a defined string with this error case just to be sure.
   */
  if (!filePath) {
    return (
      <PageCanvasError
        description={
          <>
            Something went wrong while loading the canvas. Please reload — or
            try again later.
          </>
        }
        showReloadCanvasAction
      />
    )
  }

  /**
   * This error condition is a relic from the early MVP of the canvas that still
   * used the legacy JSON format. As such only internal Sketch users that may
   * still have a JSON PR file associated with their Sketch document will ever
   * see it. Safe to remove in future.
   */
  if (filePath.includes('.json')) {
    return (
      <PageCanvasError
        description={
          <>
            Please save a new document version in Sketch and reload to see this
            page as a canvas.
          </>
        }
        showReloadCanvasAction
      />
    )
  }

  if (hasError || !page) {
    return (
      <PageCanvasError
        description={
          <>
            There was a problem fetching the page data. You can find more
            information on our{' '}
            <a href="https://status.sketch.com/">Status Page</a>.
          </>
        }
        showReloadCanvasAction
      />
    )
  }

  return (
    <PageCanvasWrapper>
      {page?.name && <DocumentHead title={`${page.name} - ${share.name}`} />}

      <StyledPageCanvas ref={wrapperRef}>
        <WebRendererCanvas
          pageUUID={pageUUID}
          page={page}
          versionShortId={versionShortId}
          shareIdentifier={share.identifier}
          initialZoom={initialZoom}
          initialPan={initialPan}
          backgroundColor={theme.colors.background.secondary.B}
          webRenderCursor={getWebRenderCursor(
            isPlacingDraftAnnotation,
            hoveredElement
          )}
          webRenderContainerProps={webRenderContainerProps.current}
        >
          <OriginContent>
            <ArtboardsInfoOverlay disableSelection={isPlacingDraftAnnotation} />

            <AnnotationsOverlay
              activeAnnotationIdentifier={activeAnnotation}
              parentWrapper={canvasContainerRef}
            />
          </OriginContent>

          {isInspectorActive && (
            <ArtboardDetailInspectorOverlay
              canvasContainerRef={canvasContainerRef}
            />
          )}

          {isInspectorActive ? (
            <ArtboardDetailInspectorContextMenu
              contextMenuPosition={
                contextMenuPos
                  ? {
                      /**
                       * On the Artboard view we have a custom context menu
                       * that works similar to the already existing "useCanvasContextMenu"
                       * so we just map the positions from one to the other
                       */
                      x: contextMenuPos.posX,
                      y: contextMenuPos.posY,
                    }
                  : null
              }
              onCloseMenu={handleCloseContextMenu}
            />
          ) : (
            <CanvasContextMenu
              permanentPageId={pageUUID}
              closeTooltip={handleCloseContextMenu}
              contextMenuPos={contextMenuPos}
              contextMenuMetaData={contextMenuMetaData}
            />
          )}
        </WebRendererCanvas>
      </StyledPageCanvas>
    </PageCanvasWrapper>
  )
})
