import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { useLocation } from 'react-router-dom';
import '../../routes/index.js';
import { Dropdown, useResponsiveDropdown, LinkButton, Navbar, Text, Link } from '@sketch/components';
import { HelpDropdownAnchor, HelpExternalArrow, MarketingWebsiteBackToWorkspaceButton, StyledAvatar, HelpButton, HelpChevron, Header, LogoWrapper, Logo } from './IndexLayout.styles.js';
import { SKETCH_WEBSITE } from '@sketch/env-config';
import '../../libs/index.js';
import { useScrollTop } from '@sketch/utils';
import { routes } from '../../routes/routes.js';
import { useUserProfile } from '../../libs/authentication/useUserProfile.js';

const headerLinkProps = {
    'sign-up': {
        link: routes.SIGN_UP.create({}),
        text: 'New to Sketch?',
        buttonCopy: 'Create an Account',
    },
    'sign-in': {
        link: routes.SIGN_IN.create({}),
        text: 'Already have an account?',
        buttonCopy: 'Sign In',
    },
    entry: {
        link: routes.ENTRY.create({}),
        text: null,
        buttonCopy: 'Back',
    },
    'workspace-settings': {
        link: routes.WORKSPACE_AGNOSTIC_SETTINGS_PEOPLE.create({}),
        text: null,
        buttonCopy: 'Back to Workspace',
    },
    'back-sign-in': {
        link: routes.SIGN_IN.create({}),
        text: null,
        buttonCopy: 'Back to Sign In',
    },
};
const HelpDropdown = () => (jsxs(Fragment, { children: [jsx(HelpDropdownAnchor, Object.assign({ href: `${SKETCH_WEBSITE}/docs/` }, { children: "Documentation" })), jsx(HelpDropdownAnchor, Object.assign({ href: `${SKETCH_WEBSITE}/support/` }, { children: "Support" })), jsx(HelpDropdownAnchor, Object.assign({ href: `${SKETCH_WEBSITE}/support/contact/` }, { children: "Contact Us" })), jsx(Dropdown.Divider, {}), jsxs(HelpDropdownAnchor, Object.assign({ href: "https://forum.sketch.com", target: "_blank" }, { children: ["Community Forum ", jsx(HelpExternalArrow, {})] })), jsxs(HelpDropdownAnchor, Object.assign({ href: "https://status.sketch.com", target: "_blank" }, { children: ["Service Status ", jsx(HelpExternalArrow, {})] }))] }));
const HeaderLink = ({ headerLink = 'none', }) => {
    var _a;
    const { state } = useLocation();
    const { data } = useUserProfile(true);
    const hasBackToWorkspaceButton = headerLink === 'workspace-settings';
    const [content, buttonProps] = useResponsiveDropdown({
        dropdown: HelpDropdown,
        dropdownStyle: { minWidth: 270 },
    });
    const headerLinkButton = hasBackToWorkspaceButton ? (jsxs(MarketingWebsiteBackToWorkspaceButton, Object.assign({ variant: "secondary", size: "40", to: {
            pathname: headerLinkProps[headerLink].link,
            state,
        } }, { children: [jsx(StyledAvatar, { src: (_a = data === null || data === void 0 ? void 0 : data.me.avatar) === null || _a === void 0 ? void 0 : _a.small, size: "32px", name: (data === null || data === void 0 ? void 0 : data.me.name) || (data === null || data === void 0 ? void 0 : data.me.email) || '?' }), headerLinkProps[headerLink].buttonCopy] }))) : (headerLink !== 'none' && (jsx(LinkButton, Object.assign({ variant: "secondary", size: "32", to: {
            pathname: headerLinkProps[headerLink].link,
            state,
        } }, { children: headerLinkProps[headerLink].buttonCopy }))));
    return (jsxs(Navbar.Section, Object.assign({ align: "end", ml: 2 }, { children: [content, jsx(HelpButton, Object.assign({ size: "48", icon: HelpChevron, iconPosition: "right" }, buttonProps, { children: "Help" })), headerLink !== 'none' && headerLinkProps[headerLink].text && (jsx(Text, Object.assign({ textStyle: "copy.secondary.standard.D", mr: 4 }, { children: headerLinkProps[headerLink].text }))), headerLinkButton] })));
};
const IndexLayoutHeader = ({ headerLink, disableLogoLink, }) => {
    const { isScrollTop } = useScrollTop();
    return (jsxs(Header, Object.assign({ "data-scrolltop": isScrollTop }, { children: [jsx(LogoWrapper, Object.assign({ "data-testid": "sketch-logo" }, { children: disableLogoLink ? (jsx(Logo, {})) : (jsx(Link, Object.assign({ to: routes.ENTRY.create({}) }, { children: jsx(Logo, {}) }))) })), jsx(HeaderLink, { headerLink: headerLink })] })));
};

export { HeaderLink, IndexLayoutHeader };
