import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import { PageLayout, LoadingState } from '@sketch/components';
import '../Footer/index.js';
import '../EmptyHeader/index.js';
import { EmptyHeader } from '../EmptyHeader/EmptyHeader.js';
import { Footer } from '../Footer/Footer.js';

const LoadingPage = (_a) => {
    var { hideFooter } = _a, props = __rest(_a, ["hideFooter"]);
    return (jsx(PageLayout, Object.assign({ header: jsx(EmptyHeader, { hideLogo: true, isDocumentView: true }), footer: !hideFooter && jsx(Footer, {}) }, { children: jsx(LoadingState, Object.assign({}, props)) })));
};

export { LoadingPage };
