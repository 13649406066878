import { Color, ColorFormat } from '../../../../../types'
import { rgbTo } from '../../components/Color/utils'

export interface CopyProps {
  artboardWidth: number
  artboardHeight: number
  color?: Color
  colorFormat?: ColorFormat
}

const copy = ({
  artboardWidth,
  artboardHeight,
  color,
  colorFormat,
}: CopyProps) => {
  const colorCopy = color ? `\nColor: ${rgbTo(colorFormat, color)}` : ``

  return `Width: ${artboardWidth}\nHeight: ${artboardHeight}` + colorCopy
}

export default copy
