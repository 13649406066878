import { __awaiter } from 'tslib';
import { jsxs, jsx } from 'react/jsx-runtime';
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { useSignOut, routes, IndexLayoutContent, IndexLayoutHeaderLink } from '@sketch/modules-common';
import { DocumentHead, LoadingPlaceholder } from '@sketch/components';
import { Wrapper } from './SignOut.styles.js';
import { useHistory } from 'react-router';

const SignOutView = (props) => {
    const { HeaderPortal } = props;
    const history = useHistory();
    const signOut = useSignOut({
        redirectBackAfterLoginAgain: false,
        reason: 'Logout button',
    });
    useEffect(() => {
        const asyncSignOut = () => __awaiter(void 0, void 0, void 0, function* () {
            yield signOut();
            history.replace(routes.SIGN_IN.create({}));
        });
        asyncSignOut();
    }, [signOut, history]);
    return (jsxs(IndexLayoutContent, Object.assign({ center: "horizontal", marginTop: true, paddingHorizontal: true }, { children: [jsx(HeaderPortal, { children: jsx(IndexLayoutHeaderLink, {}) }), jsxs(Helmet, { children: [jsx("link", { rel: "canonical", href: "https://www.sketch.com/signin/" }), jsx("meta", { property: "og:url", content: "https://www.sketch.com/signin/" })] }), jsx(DocumentHead, { title: "Sign in - It's great to see you again" }), jsx(Wrapper, { children: jsx(LoadingPlaceholder, { size: "64px" }) })] })));
};

export { SignOutView };
