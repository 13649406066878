import React from 'react'
import { useStatus } from '@sketch-hq/sketch-web-renderer'
import { useLocation } from 'react-router'
import { parse, stringify } from 'query-string'

import { routes } from '@sketch/modules-common'
import { shortcuts } from '@sketch/utils'

import { ReactComponent as ChatBubbleAddIcon } from '@sketch/icons/chat-bubble-add-16'

import {
  useIsAnnotationContextAvailable,
  usePlaceDraftAnnotation,
} from '../../hooks'

import AnnotationInboxList from '../../components/AnnotationInboxList'
import ShareSubscriptionStatus from '../../components/ShareSubscriptionStatus'
import CommentTabMessageRestricted from '../../components/CommentTabMessageRestricted'
import { Share } from '../../types'
import { TooltipShortcut } from '@sketch/components'
import { AddAnnotationButton } from './ShareAnnotations.style'

interface ShareAnnotationsProps {
  share: Share
  showAddAnnotationButton?: boolean
}

const ShareAnnotations: React.FC<ShareAnnotationsProps> = props => {
  const { share, showAddAnnotationButton } = props

  const location = useLocation()
  const isAnnotationContextAvailable = useIsAnnotationContextAvailable()
  const [isPlacingAnnotation, setPlaceAnnotation] =
    usePlaceDraftAnnotation() || []

  const canvasStatus = useStatus()

  if (!share.commentsEnabled) {
    return <CommentTabMessageRestricted />
  }

  const queryArguments = parse(location.search)
  const annotation = Array.isArray(queryArguments.annotation)
    ? queryArguments.annotation[0]!
    : queryArguments.annotation || undefined

  return (
    <AnnotationInboxList
      activeAnnotationIdentifier={annotation}
      navbarAction={
        <>
          <ShareSubscriptionStatus shareIdentifier={share.identifier} />

          {showAddAnnotationButton && (
            <AddAnnotationButton
              onClick={(event: MouseEvent) => {
                setPlaceAnnotation?.(!isPlacingAnnotation)
                event.stopPropagation()
              }}
              disabled={canvasStatus?.type !== 'READY'}
              aria-current={isPlacingAnnotation}
              description={
                <>
                  Add comment{' '}
                  <TooltipShortcut>
                    Press {shortcuts.addComment.toUpperCase()}
                  </TooltipShortcut>
                </>
              }
            >
              <span className="sr-only">New comment</span>
              <ChatBubbleAddIcon role="img" />
            </AddAnnotationButton>
          )}
        </>
      }
      resolveAnnotationLink={annotation => {
        /*
         * Assume we're dealing with a *floating annotation* unless the user is
         * on the canvas fallback page (i.e. the canvas has failed to load).
         *
         * TODO: disallow floating annotations for CWVs
         * https://github.com/sketch-hq/Cloud/issues/14254
         */
        if (isAnnotationContextAvailable) {
          return location => ({
            ...location,
            state: {
              isFromCommentTab: true,
            },
            search: stringify({
              ...queryArguments,
              annotation: annotation.identifier,
            }),
          })
        }

        /**
         * Otherwise it should default to the artboard
         * view like we have been using.
         *
         * TODO: show non-floating annotations in the document view
         * https://github.com/sketch-hq/Cloud/issues/15430
         */
        if (annotation.currentSubject.__typename === 'Artboard') {
          const permanentArtboardShortId =
            annotation.currentSubject.permanentArtboardShortId

          return routes.ARTBOARD_DETAIL.create({
            shareID: share.identifier,
            permanentArtboardShortId,
            query: { ...queryArguments, annotation: annotation.identifier },
          })
        }
      }}
    />
  )
}

export default ShareAnnotations
