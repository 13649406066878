import { __awaiter } from 'tslib';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Modal, Form, Input, PasswordInput, Button } from '@sketch/components';
import { validEmail, castError } from '@sketch/utils';
import { useToast } from '@sketch/toasts';
import { useEmailUpdateMutation } from '@sketch/gql-types';
import { StyledForm } from './ChangeEmailModal.styles.js';

const SCHEMA = yup.object().shape({
    email: validEmail().required('Email can’t be blank'),
    password: yup.string().required('Current Password can’t be blank'),
});
const ChangeEmailModal = ({ initialEmail, hideModal, }) => {
    const [updateEmail] = useEmailUpdateMutation({
        redirectErrors: false,
        onError: 'unsafe-throw-exception',
    });
    const { showToast } = useToast();
    const emailInput = useRef(null);
    useEffect(() => {
        var _a, _b;
        (_a = emailInput.current) === null || _a === void 0 ? void 0 : _a.focus();
        (_b = emailInput.current) === null || _b === void 0 ? void 0 : _b.select();
    }, [emailInput]);
    const handleSubmit = (values, actions) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (initialEmail === values.email) {
            actions.setFieldError('email', `This is the email you're currently using`);
            return;
        }
        try {
            const { data } = yield updateEmail({
                variables: {
                    email: values.email,
                    password: values.password,
                },
            });
            const emailUpdate = data === null || data === void 0 ? void 0 : data.emailUpdate;
            if (((_b = (_a = emailUpdate === null || emailUpdate === void 0 ? void 0 : emailUpdate.errors) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) > 0) {
                throw emailUpdate.errors[0];
            }
            const successMessage = `Please confirm your new email address using the link we sent to '${values.email}'`;
            showToast(successMessage, 'positive');
            hideModal();
        }
        catch (e) {
            const error = castError(e);
            showToast(error.message, 'negative');
        }
        finally {
            actions.setSubmitting(false);
        }
    });
    return (jsx(Modal, Object.assign({ title: "Change Your Email Address", onCancel: hideModal }, { children: jsx(Formik, Object.assign({ initialValues: { email: initialEmail, password: '' }, validationSchema: SCHEMA, onReset: hideModal, onSubmit: handleSubmit }, { children: ({ isSubmitting, handleReset, values, errors, touched, handleChange, handleBlur, }) => (jsxs(Fragment, { children: [jsx(Modal.Body, { children: jsxs(StyledForm, Object.assign({ id: "change-email-form" }, { children: [jsx(Form.Field, Object.assign({ name: "email", label: "Email", errorText: touched.email ? errors.email : undefined }, { children: jsx(Input, { name: "email", type: "text", placeholder: "john@example.com", ref: emailInput, value: values.email, disabled: isSubmitting, onChange: handleChange, onBlur: handleBlur }) })), jsx(Form.Field, Object.assign({ name: "password", label: "Enter Your Password to Confirm", errorText: touched.password ? errors.password : undefined }, { children: jsx(PasswordInput, { name: "password", "aria-label": "Password", value: values.password, disabled: isSubmitting, onChange: handleChange, onBlur: handleBlur }) }))] })) }), jsxs(Modal.Footer, { children: [jsx(Button, Object.assign({ type: "reset", variant: "secondary", disabled: isSubmitting, onClick: handleReset }, { children: "Cancel" })), jsx(Button, Object.assign({ type: "submit", variant: "primary", loading: isSubmitting, disabled: isSubmitting ||
                                    values.email.length === 0 ||
                                    values.password.length === 0, form: "change-email-form" }, { children: "Change Email" }))] })] })) })) })));
};

export { ChangeEmailModal };
