import React, { CSSProperties } from 'react'
import { ColorFormat, Color as ColorType } from '../../../../../../types'
import styled, { css } from 'styled-components'
import ColorBackground from '@sketch/icons/color-background.svg'
import { truncateStyles } from '@sketch/components'

export interface ColorProps extends ColorType {
  style?: CSSProperties
  displayOpacity?: boolean
  format?: ColorFormat
  className?: string
  $iconSize?: number
}

export const Container = styled.div`
  flex: auto;
  min-width: 0;

  display: flex;
  align-items: center;

  font-size: 0.8125rem;
`

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.foreground.secondary.B};
  ${truncateStyles};
`

const ColorIconUnstyled = ({
  red,
  green,
  blue,
  alpha,
  ...props
}: ColorProps) => (
  <div
    role="img"
    aria-label={`rgba(${red}, ${green}, ${blue}, ${alpha})`}
    {...props}
  ></div>
)

export const ColorDotStyle = css<ColorProps>(
  ({ theme, $iconSize, red, green, blue, alpha }) => css`
    display: inline-block;
    margin-right: 8px;
    position: relative;
    height: ${$iconSize ?? 16}px;
    width: ${$iconSize ?? 16}px;
    flex: none;

    &::before,
    &::after {
      content: '';
      border-radius: 50%;
      position: absolute;
      inset: 0;
    }

    &::before {
      content: '';
      background-image: url(${ColorBackground});
      background-position: center center;
      background-size: cover;
    }

    &::after {
      border: 1px solid ${theme.colors.border.A};
      background: rgba(${red}, ${green}, ${blue}, ${alpha});
    }
  `
)

export const ColorIcon = styled(ColorIconUnstyled)<ColorProps>(
  () => css`
    ${ColorDotStyle}
  `
)

export const textOverflowFadeEffect = css`
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  /* ^ needed to ensure proper positioning of the :after section below */

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 108px,
      rgba(255, 255, 255, 1) 100%
    );
    /* ^ creates a text-overflow fade effect */
    width: 100%;
    height: 100%;
  }
`
