import { __rest, __awaiter } from 'tslib';
import { jsxs, Fragment, jsx } from 'react/jsx-runtime';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Form, Input, Flex, Link, PasswordInput, Button } from '@sketch/components';
import '../../routes/index.js';
import '../../libs/authentication/index.js';
import { validEmail } from '@sketch/utils';
import { routes } from '../../routes/routes.js';
import { useSignIn } from '../../libs/authentication/useSignIn/useSignIn.js';

const SIGN_IN_INITIAL_VALUES = {
    email: '',
    password: '',
};
const signInSchema = yup.object().shape({
    email: validEmail().required('Email can’t be blank'),
    password: yup.string().required('Password can’t be blank'),
});
const Fields = ({ values, errors, touched, handleChange, handleBlur, hideForgotPasswordLink, forgotPasswordLink, }) => (jsxs(Fragment, { children: [jsx(Form.Field, Object.assign({ name: "text", label: "Email", errorText: touched.email ? errors.email : undefined }, { children: jsx(Input, { name: "email", type: "email", placeholder: "Enter your email", defaultValue: values.email, onChange: handleChange, onBlur: handleBlur, autoFocus: true }) })), jsx(Form.Field, Object.assign({ name: "password", errorText: touched.password ? errors.password : undefined, label: jsxs(Flex, Object.assign({ justifyContent: "space-between", alignItems: "center" }, { children: ["Password", !hideForgotPasswordLink && (jsx(Link, Object.assign({ className: "FormLabel__aside", variant: "primary", isUnderlined: true, tabIndex: -1, to: forgotPasswordLink
                            ? forgotPasswordLink
                            : { pathname: routes.FORGOT_PASSWORD.create({}) } }, { children: "Forgot Password?" })))] })) }, { children: jsx(PasswordInput, { name: "password", placeholder: "Password", defaultValue: values.password, onChange: handleChange, onBlur: handleBlur }) }))] }));
const Submit = ({ isSubmitting, disabled, submitCopy = 'Sign In', }) => (jsx(Form.Footer, { children: jsx(Button, Object.assign({ variant: "primary", type: "submit", fill: true, size: "40", disabled: isSubmitting || disabled, loading: isSubmitting }, { children: submitCopy })) }));
const Errors = ({ apiErrors }) => (apiErrors === null || apiErrors === void 0 ? void 0 : apiErrors.message) ? (jsx(Form.ErrorField, { children: apiErrors.message })) : null;
const DefaultFormFields = (_a) => {
    var { submitCopy } = _a, formProps = __rest(_a, ["submitCopy"]);
    return (jsxs(Fragment, { children: [jsx(Fields, Object.assign({}, formProps)), jsx(Errors, Object.assign({}, formProps)), jsx(Submit, { disabled: !(formProps.isValid && formProps.dirty), isSubmitting: formProps.isSubmitting, submitCopy: submitCopy })] }));
};
const SignInForm = ({ children = DefaultFormFields, email = '', submitCopy = 'Sign In', isLoading, onCompleted, onSubmit, }) => {
    const [signIn, { loading, error }] = useSignIn({
        onCompleted,
    });
    const handleSubmit = ({ email, password }) => __awaiter(void 0, void 0, void 0, function* () {
        // Allows to use this form but without running the signIn hook
        if (onSubmit) {
            onSubmit({ email, password });
        }
        else {
            try {
                yield signIn({ variables: { email, password } });
            }
            catch (e) {
                // The error handling should be taken care of by Apollo
            }
        }
    });
    const isSubmitting = !!(loading || isLoading);
    return (jsx(Formik, Object.assign({ initialValues: Object.assign(Object.assign({}, SIGN_IN_INITIAL_VALUES), { email }), onSubmit: handleSubmit, validationSchema: signInSchema, validateOnBlur: false }, { children: formikBag => (jsx(Form, { children: children(Object.assign(Object.assign({}, formikBag), { isSubmitting, apiErrors: error, submitCopy })) })) })));
};

export { DefaultFormFields, Errors, Fields, Submit, SignInForm as default };
