import React, { useEffect, useMemo } from 'react'

import { useDropdownState } from '@sketch/components'
import { useInspectorContext } from '../../hooks/inspector'

import { SketchElement, isLayerElement } from '../../../../inspector'

import { useSubSketchElementsTreeAtRelativePosition } from './useSubSketchElementsTreeAtRelativePosition'
import { ElementIcon } from './ElementIcon'

import * as S from './ArtboardDetailInspectorContextMenu.styles'
import { Coordinates } from '../../../../annotations/types'

type ArtboardDetailInspectorContextMenuProps = {
  className?: string
  /**
   * Menu position (relative to the artboard element)
   */
  contextMenuPosition: Coordinates | null
  onCloseMenu: () => void
}

/**
 * The context menu opening when right clicking on the artboard canvas when
 * using the inspector. This shows a tree of all the sketch elements under the cursor.
 */
export function ArtboardDetailInspectorContextMenu({
  className,
  contextMenuPosition,
  onCloseMenu,
}: ArtboardDetailInspectorContextMenuProps) {
  const {
    selectedElement,
    setSelectedElement,
    sketchSceneRootElement,
  } = useInspectorContext()

  const subSketchSceneRootElement = useSubSketchElementsTreeAtRelativePosition(
    contextMenuPosition
  )
  const dropdown = useDropdownState({
    onToggle: onCloseMenu,
  })

  const elementsList = useMemo(() => {
    if (!subSketchSceneRootElement) {
      return []
    }

    /**
     * We wanna prevent the root element from the PRFile of showing on the context menu
     * (page or artboard) so we are checking if the UUID is the same and passing it's children
     *
     * We compare the UUIDs because the "subSketchSceneRootElement" contains a trimmed version of the
     * "sketchSceneRootElement"
     */
    if (
      sketchSceneRootElement?.elementUUID ===
      subSketchSceneRootElement?.elementUUID
    ) {
      return subSketchSceneRootElement.children
    }

    return [subSketchSceneRootElement]
  }, [subSketchSceneRootElement, sketchSceneRootElement])

  useEffect(() => {
    if (elementsList.length === 0 && contextMenuPosition) {
      // If the user right clicked to open menu but we realize that there is nothing
      // to show where the user clicked, reset the "open" position by closing the menu.
      onCloseMenu()
    }
  }, [contextMenuPosition, elementsList.length, onCloseMenu])

  if (
    !contextMenuPosition ||
    !subSketchSceneRootElement ||
    // We are already closing the menu in the use effect above but just in case,
    // let's avoid render the menu at all.
    elementsList.length === 0
  ) {
    return null
  }

  return (
    <S.DropdownWrapper
      {...dropdown}
      onClickOutside={dropdown.toggleDropdown}
      placement="auto-start"
      style={{
        left: contextMenuPosition.x,
        top: contextMenuPosition.y,
      }}
      contentStyle={{ maxWidth: 500 }}
      className={className}
      visible={true} // through the visible flag
      maxWidth="100%"
    >
      <S.ContextMenuNav>
        <ContextMenuList
          elementsList={elementsList}
          level={0}
          selectedLayerUuid={selectedElement?.elementUUID}
          onSelect={(sketchElement: SketchElement) => {
            setSelectedElement(sketchElement)
            onCloseMenu()
          }}
        />
      </S.ContextMenuNav>
    </S.DropdownWrapper>
  )
}

type ContextMenuListProps = {
  elementsList: SketchElement[]
  level: number
  selectedLayerUuid: string | undefined
  onSelect: (sketchElement: SketchElement) => void
}
/**
 * A recursive component that renders a tree of elements into ordered lists
 */
function ContextMenuList({
  elementsList,
  level,
  selectedLayerUuid,
  onSelect,
}: ContextMenuListProps) {
  return (
    <ol key={`level${level}`}>
      {elementsList.map(sketchElement => {
        const isLayerMasked = isLayerElement(sketchElement)
          ? sketchElement.isLayerMasked
          : false

        return (
          <li
            key={sketchElement.identifier.toString()}
            onClick={e => {
              // Make sure the click doesn't propagate to the parent
              // since this component is rendered recursively.
              e.stopPropagation()
              onSelect(sketchElement)
            }}
          >
            <S.ContextMenuLabel
              level={level}
              isSelected={selectedLayerUuid === sketchElement.elementUUID}
            >
              <ElementIcon
                elementType={sketchElement.type}
                isLayerMasked={isLayerMasked}
                isLayerExportable={sketchElement.isExportable}
              />
              <p>{sketchElement.name}</p>
            </S.ContextMenuLabel>
            <ContextMenuList
              elementsList={sketchElement.children}
              level={level + 1}
              selectedLayerUuid={selectedLayerUuid}
              onSelect={onSelect}
            />
          </li>
        )
      })}
    </ol>
  )
}
