import React from 'react'
import { useHistory } from 'react-router-dom'
import { ApolloError } from 'apollo-client'

// External components
import {
  Box,
  Flex,
  Text,
  TimeAgo,
  LoadingState,
  ErrorMessage,
} from '@sketch/components'
import EditorNavbar from '../../views/EditorView/EditorNavbar'
import { GenericErrorView, routes, useFlag } from '@sketch/modules-common'
import ShareNavbar from '../../../shares/components/ShareNavbar'
import DocumentItemsLayout from '../../../shares/components/DocumentItemsLayout'
import { DocumentItemDropdown } from '../../../shares/components/DocumentItemDropdown'
import { DesignSystemDropdown } from '../../components/DesignSystemDropdown'
import { Header } from './Header'
import { ErrorHandler } from '@sketch/tracing'
import EmptyState from '../../../shares/components/EmptyState'
import { DsmTextEditor } from '../../components'
import { WorkspaceSidebarLayoutExtraProps } from '../../../workspace/components/WorkspaceSidebarLayout/WorkspaceSidebarLayout'
import { GetDesignSystemQuery } from '@sketch/gql-types'

import { Wrapper, Logo } from './DesignSystem.styles'

import { DesignSystem as DesignSystemType } from '../../types'

import { useSearch } from '../../../shares/hooks/useSearch'

interface DesignSystemViewProps {
  layoutProps: WorkspaceSidebarLayoutExtraProps
  data: GetDesignSystemQuery | undefined
  loading: boolean
  error: ApolloError | undefined
  loadMoreHandler: () => Promise<any>
  variables: {
    identifier: string
    search: string
  }
}

const defaultEditorContent = {
  content: [
    {
      attrs: {
        level: 1,
      },
      content: [
        {
          text: 'Text editor playground',
          type: 'text',
        },
      ],
      type: 'heading',
    },
    {
      content: [
        {
          text:
            'This is a description for the design system page, you can just click and start writing...',
          type: 'text',
        },
      ],
      type: 'paragraph',
    },
  ],
  type: 'doc',
}

export const DesignSystem: React.FC<DesignSystemViewProps> = ({
  layoutProps,
  data,
  loading,
  error,
  loadMoreHandler,
}) => {
  const { workspace, NavbarPortal, useOverrideLayoutProps } = layoutProps

  useOverrideLayoutProps({
    title: data ? `Design System - ${data.designSystem.name}` : 'Design System',
    hideFooter: true,
  })

  const history = useHistory()
  const { search } = useSearch()
  const isDesignSystemsEditorOn = useFlag('design-systems-editor')

  if (loading && !data) {
    return <LoadingState />
  }

  if (!data) {
    return <ErrorMessage.Generic />
  }

  if (error) {
    return <GenericErrorView error={error} isInLayout />
  }

  const designSystem = data.designSystem
  const designSystemLibraries = designSystem.shares.entries || []
  const userIsGuest =
    workspace?.userRole === 'GUEST' || workspace?.userRole === undefined

  const totalShareCount = designSystem.shares.meta.totalCount || 0

  const handleDelete = () => {
    if (!workspace.identifier) {
      return
    }

    history.push(
      routes.WORKSPACE_LIBRARIES.create({ workspaceId: workspace.identifier })
    )
  }

  return (
    <>
      <NavbarPortal>
        {isDesignSystemsEditorOn ? (
          <EditorNavbar
            userIsGuest={userIsGuest}
            designSystem={designSystem as DesignSystemType}
          />
        ) : (
          <ShareNavbar
            userRole={workspace.userRole}
            showViewOptions={Boolean(totalShareCount)}
          />
        )}
      </NavbarPortal>

      <Wrapper>
        <Header
          headerURL={designSystem.header}
          designSystemId={designSystem.identifier}
          userIsGuest={userIsGuest}
        />
        <Flex borderBottom="1px solid" borderColor="border.B" p={10}>
          {designSystem.preview && (
            <Box mr={4}>
              <Logo src={designSystem.preview} />
            </Box>
          )}
          <Flex flexDirection="column">
            <Flex>
              <Text textStyle="header.primary.H" mr={3}>
                {designSystem.name}
              </Text>
              <DesignSystemDropdown
                deleteCallback={handleDelete}
                designSystem={designSystem as DesignSystemType}
                url={routes.DESIGN_SYSTEM.create({
                  workspaceId: workspace.identifier,
                  designSystemId: designSystem.identifier,
                })}
                userIsGuest={userIsGuest}
              />
            </Flex>
            <Box mt={1} mb={3}>
              <Text textStyle="copy.tertiary.standard.E">
                {designSystem.description || 'No description added.'}
              </Text>
            </Box>
            <Text textStyle="copy.quaternary.standard.C">
              Updated <TimeAgo date={designSystem.updatedAt} />
            </Text>
          </Flex>
        </Flex>

        {isDesignSystemsEditorOn && (
          <Flex borderBottom="1px solid" borderColor="border.B" p={10}>
            <DsmTextEditor content={defaultEditorContent} />
          </Flex>
        )}

        {totalShareCount ? (
          <Flex p={10} flexDirection="column" flex="auto">
            <Text textStyle="header.primary.G" mb={8}>
              Linked Libraries
            </Text>

            {loading ? (
              <LoadingState />
            ) : (
              <DocumentItemsLayout
                shares={designSystemLibraries}
                renderDropdown={({ identifier }) => {
                  const share = designSystemLibraries.find(
                    library => library.identifier === identifier
                  )
                  if (!share) {
                    ErrorHandler.shouldNeverHappen(
                      'Library should always be present rendering the DocumentDropdown'
                    )
                    return null
                  }
                  return (
                    <DocumentItemDropdown
                      share={share}
                      workspaceIdentifier={workspace.identifier}
                      // TODO: We won't be pinning DS yet
                      queryVariables={{}}
                      workspaceStatus={workspace.status}
                    />
                  )
                }}
                onLoadMore={loadMoreHandler}
                totalCount={totalShareCount}
                showProjectName={false}
              />
            )}
          </Flex>
        ) : (
          <>
            <Text textStyle="header.primary.G" mb={8} ml={10} mt={10}>
              Linked Libraries
            </Text>
            <EmptyState
              search={search}
              title="No Libraries available to view yet"
              description="Libraries shared with you in this Workspace will appear here"
              icon="link"
            />
          </>
        )}
      </Wrapper>
    </>
  )
}
