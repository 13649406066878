import React, { useEffect, useRef, useCallback } from 'react'

import { ErrorHandler } from '@sketch/tracing'
import { getMemberEmail } from '../../utils'

import { useMembershipsPanel } from './hooks'

import { capitalize } from '@sketch/utils'
import { getColumnValues } from './utils'

import {
  Button,
  ErrorMessage,
  pluralize,
  Tooltip,
  Table,
  TableComponents,
  useForTablet,
} from '@sketch/components'
import { BadgeType } from '../MemberBadge/MemberBadge'

import RoleTabs from './components/RoleTabs'
import MembersTableLoading from './components/MembersTableLoading'

import {
  EmptyList,
  ErrorContainer,
  Filter,
  LoadMoreWrapper,
  MemberCard,
  TableWrapper,
  StatusCell,
  StyledTableCell,
  SearchWrapper,
  StyledButton,
} from './MembershipsPanel.styles'

import {
  WorkspaceMembershipFragment,
  GetWorkspaceMembershipsQueryVariables,
  useWorkspaceMembersReportMutation,
} from '@sketch/gql-types'

import { useUserProfile } from '@sketch/modules-common'

type WorkspaceMembershipOrderType = GetWorkspaceMembershipsQueryVariables['order']

interface MembershipsPanelProps {
  workspaceId: string
  renderSecondaryActions?: (
    member: WorkspaceMembershipFragment
  ) => React.ReactNode
  userDirectoryEnabled?: boolean
  partnersNotVisible?: boolean
  isBusinessWorkspace?: boolean
}

const getRoleText = (
  userRole: WorkspaceMembershipFragment['role'],
  isEditor: boolean
) => {
  if (userRole === 'GUEST') {
    return 'Guest'
  } else if (isEditor) {
    return 'Editor'
  } else {
    return 'Viewer'
  }
}

const formatDateTooltip = (days: number) => {
  if (!days) {
    return null
  }
  if (days <= 30) return `${days} ${pluralize('day', 'days', days)} ago`
  if (days > 30) {
    const month = Math.floor(days / 30)
    return `${month} ${pluralize('month', 'months', month)} ago`
  }
}

const MAX_SCROLL_VALUE = 112

type TooltipContentProps = {
  member: WorkspaceMembershipFragment
}

const TooltipContent: React.FC<TooltipContentProps> = ({ member }) => {
  const tooltipText =
    'Activity is either the most recent Member sign-in, or when last the access token was refreshed in the Mac app. Activity is tied to a Member’s account, not a Workspace'
  const tooltipTextGuests = 'Guests’ activity isn’t tracked'
  const tooltipTextViewers = 'Viewers’ activity isn’t tracked'
  const tooltipTextPending = 'Pending Members won’t show any activity yet'

  if (getRoleText(member.role, member.isEditor) === 'Guest') {
    return <Tooltip.Body>{tooltipTextGuests}</Tooltip.Body>
  }
  if (getRoleText(member.role, member.isEditor) === 'Viewer') {
    return <Tooltip.Body>{tooltipTextViewers}</Tooltip.Body>
  }

  if (!member.acceptedAt) {
    return <Tooltip.Body>{tooltipTextPending}</Tooltip.Body>
  }

  return (
    <>
      <Tooltip.Title>{`Last Activity: ${formatDateTooltip(
        member.activityStatus?.lastSeenInDays as number
      )}`}</Tooltip.Title>
      <Tooltip.Body>{tooltipText}</Tooltip.Body>
    </>
  )
}

/**
 * MembershipsPanel
 *
 * Renders the table of members in the Workspace settings page
 */

export const MembershipsPanel: React.FC<MembershipsPanelProps> = ({
  workspaceId,
  renderSecondaryActions,
  partnersNotVisible,
  userDirectoryEnabled,
  isBusinessWorkspace,
}) => {
  const { data: userProfile } = useUserProfile()
  const isTabletAndBigger = useForTablet()
  const hasClickedSortByStatusRef = useRef(false)

  const {
    loading,
    error,
    data,
    fetchMore,
    userCount,
    filters,
    previousNumberOfMemberRows,
  } = useMembershipsPanel(workspaceId)

  const tableWrapperRef = useRef<HTMLDivElement>(null)
  const tableWrapperLoadingRef = useRef<HTMLDivElement>(null)

  const [
    membersReport,
    { loading: isLoadingReport },
  ] = useWorkspaceMembersReportMutation({
    variables: { workspaceIdentifier: workspaceId },
    onError: 'show-toast',
    onCompleted: ({ workspaceMembersReport }) => {
      const url = workspaceMembersReport.downloadUrl

      // With this we can trigger a download from a URL
      // without Safari blocking pop-ups.
      const a = document.createElement('a')
      a.setAttribute('data-testid', 'csv-report')
      a.href = url
      a.click()
      a.remove()
    },
  })

  useEffect(() => {
    if (filters.order === 'ACTIVITY_STATUS') {
      if (hasClickedSortByStatusRef?.current && tableWrapperRef.current) {
        tableWrapperRef.current.scrollLeft = MAX_SCROLL_VALUE
      }

      if (tableWrapperLoadingRef.current) {
        tableWrapperLoadingRef.current.scrollLeft = MAX_SCROLL_VALUE
      }
    }
  }, [data, filters.order])

  const currentUserId = userProfile?.me.identifier
  const isSuperadmin = !!userProfile?.me.isSuperadmin

  const handleDownloadCSV = () => {
    membersReport()
  }

  // Render each row in the members table
  const renderMemberRow = useCallback(
    (member: WorkspaceMembershipFragment) => {
      // Builds an array of badges for each member in the memberships table
      const getBadges = (member: WorkspaceMembershipFragment) => {
        const badges: BadgeType[] = []

        if (member.user?.identifier === currentUserId && isSuperadmin) {
          badges.push('super-admin')
        }

        if (member.isOwner) {
          badges.push('owner')
        }

        if (member.role === 'ADMIN' && !member.isOwner) {
          badges.push('admin')
        }

        if (member.role === 'FINANCE') {
          badges.push('finance')
        }

        if (member.role === 'PARTNER') {
          badges.push('partner')
        }

        if (!member.acceptedAt) {
          badges.push('pending-invite')
        }

        return badges
      }

      let status = member?.activityStatus?.status
        ? capitalize(member?.activityStatus?.status)
        : '—'

      // We manually disable status for guests, viewers and pending members until the backend gains more granularity whe tracking user activity
      if (
        ['Guest', 'Viewer'].includes(
          getRoleText(member.role, member.isEditor)
        ) ||
        !member.acceptedAt
      ) {
        status = '—'
      }

      const tooltipDisabled =
        !member.activityStatus?.lastSeenInDays &&
        member.isEditor &&
        !!member.acceptedAt

      return (
        <TableComponents.TableRow data-testid="members-table-row">
          <StyledTableCell>
            <MemberCard
              name={member?.user?.name}
              email={getMemberEmail(member)!}
              avatarSrc={member?.user?.avatar?.small}
              $dimmed={!member.acceptedAt}
              badges={getBadges(member)}
            />
          </StyledTableCell>
          <TableComponents.TableCell data-testid="member-access">
            <span>{getRoleText(member.role, member.isEditor)}</span>
          </TableComponents.TableCell>
          {renderSecondaryActions && (
            <>
              <TableComponents.TableCell data-testid="member-access">
                <Tooltip
                  placement="top"
                  disabled={tooltipDisabled}
                  content={<TooltipContent member={member} />}
                >
                  <StatusCell $isActive={status === 'Active'}>
                    {status}
                  </StatusCell>
                </Tooltip>
              </TableComponents.TableCell>
              <TableComponents.TableCellSticky>
                {renderSecondaryActions(member)}
              </TableComponents.TableCellSticky>
            </>
          )}
        </TableComponents.TableRow>
      )
    },
    [renderSecondaryActions, currentUserId, isSuperadmin]
  )

  const { tableWidth, firstColWidth, tableHeader } = getColumnValues(
    !!renderSecondaryActions,
    filters.order
  )

  /* Render a Skeleton table for better loading experience */
  let children = (
    <MembersTableLoading
      ref={tableWrapperLoadingRef}
      items={previousNumberOfMemberRows.current}
      renderSecondaryActions={!!renderSecondaryActions}
      order={filters.order}
      sortDirection={filters.orderDirection}
    />
  )

  if (error) {
    children = (
      <ErrorContainer>
        <ErrorMessage.Generic />
      </ErrorContainer>
    )
  }

  if (data) {
    const {
      userCanAccess,
      list,
      totalMemberships,
      totalGuests,
      totalMembers,
    } = data.workspace.memberships

    if (!userCanAccess) {
      // TODO: find a better !userCanAccess handling strategy
      // see: https://github.com/sketch-hq/Cloud/issues/7500
      return null
    }

    if (!list || !totalMemberships) {
      ErrorHandler.shouldNeverHappen(
        'memberships should contain a list if userCanAccess is true'
      )
      return null
    }

    const { entries: memberships } = list
    const membershipsWithoutPartner = partnersNotVisible
      ? memberships.filter(({ role }: { role: string }) => role !== 'PARTNER')
      : memberships
    const { totalCount: listCount } = list.meta
    const { totalCount: totalCountUsers } = totalMemberships.meta
    const { totalCount: totalCountGuests } = totalGuests?.meta || {
      totalCount: 0,
    }
    const { totalCount: totalCountMembers } = totalMembers?.meta || {
      totalCount: 0,
    }

    /*
      We are only updating the total counts if they differ
      from the previous value. This will avoid always rendering
      the Skeleton UI in the filter tabs when changing filter tabs.
    */
    if (userCount?.current?.totalMemberships !== totalCountUsers) {
      userCount.current.totalMemberships = totalCountUsers
    }

    if (userCount?.current?.totalMembers !== totalCountMembers) {
      userCount.current.totalMembers = totalCountMembers
    }

    if (userCount?.current?.totalGuests !== totalCountGuests) {
      userCount.current.totalGuests = totalCountGuests
    }

    /* Renders the members table */
    children = (
      <>
        {!membershipsWithoutPartner.length ? (
          <EmptyList>No Members found.</EmptyList>
        ) : (
          <TableWrapper
            ref={tableWrapperRef}
            $tableWidth={tableWidth}
            $firstColWidth={firstColWidth}
            $isSticky={!!renderSecondaryActions}
          >
            <Table
              data-testid="memberships-table"
              header={tableHeader}
              items={membershipsWithoutPartner}
              renderItem={renderMemberRow}
              sortDirection={filters.orderDirection}
              sortable
              onHeaderClick={(sortKey: WorkspaceMembershipOrderType) => {
                hasClickedSortByStatusRef.current = true
                filters.onSortingChange(sortKey)
              }}
            />
          </TableWrapper>
        )}

        {/** Pagination: Load More button */}
        {listCount > memberships.length ? (
          <LoadMoreWrapper>
            <Button
              variant="secondary-untinted"
              size="40"
              onClick={fetchMore}
              loading={loading}
            >
              Load More
            </Button>
          </LoadMoreWrapper>
        ) : null}
      </>
    )
  }

  return (
    <>
      <RoleTabs
        filters={filters}
        userCount={userCount}
        userDirectoryEnabled={userDirectoryEnabled}
      />
      <SearchWrapper>
        <Filter
          data-test-id="search-filter"
          name="search-filter"
          placeholder="Search by name or email"
          onChange={filters.setFilter}
          value={filters.filter}
          fullWidth
        />
        {isBusinessWorkspace &&
          // This should only render for workspace admins  and only workspace admins can render
          // secondary actions so we use it here to avoid prop drilling
          renderSecondaryActions && (
            <StyledButton
              onClick={handleDownloadCSV}
              loading={isLoadingReport}
              size={isTabletAndBigger ? '32' : '40'}
            >
              Download CSV
            </StyledButton>
          )}
      </SearchWrapper>
      {children}
    </>
  )
}
