import { jsx, jsxs } from 'react/jsx-runtime';
import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { IndexLayoutContent, IndexLayoutHeaderLink, RedirectBadge, SignUpForm, routes } from '@sketch/modules-common';
import { ExternalLink, Link } from '@sketch/components';
import { isMac } from '@sketch/utils';
import { useSignUpFormContextForAnalytics } from './useSignUpFormContextForAnalytics.js';
import { Container, RequiredOSVersion, OperatingSystemWarning, Line, TextSignUp } from './SignUpView.styles.js';

const BaseSignUpView = ({ email, invitedUser, onCompleted, useOverrideLayoutProps, HeaderPortal, }) => {
    const { state } = useLocation();
    const showOSWarning = !isMac();
    const getTheme = useCallback((theme) => overriddenThemeFor(theme), []);
    useOverrideLayoutProps({ maxContainerWidth: '960px' });
    const formContextForAnalytics = useSignUpFormContextForAnalytics();
    return (jsx(ThemeProvider, Object.assign({ theme: getTheme }, { children: jsxs(IndexLayoutContent, Object.assign({ center: "horizontal", marginTop: true, paddingHorizontal: true }, { children: [jsx(HeaderPortal, { children: jsx(IndexLayoutHeaderLink, {}) }), (state === null || state === void 0 ? void 0 : state.from) && jsx(RedirectBadge, Object.assign({}, state)), jsxs(Container, { children: [jsxs(RequiredOSVersion, { children: ["To run Sketch you\u2019ll need a macOS device that meets our", ' ', jsx(ExternalLink, Object.assign({ href: "http://sketch.com/redirect/web/system-requirements" }, { children: "system requirements" })), "."] }), jsx(SignUpForm, { email: email, invitedUser: invitedUser, onCompleted: onCompleted, formContextForAnalytics: formContextForAnalytics }), showOSWarning && (jsxs(OperatingSystemWarning, Object.assign({ "data-testid": "os-warning" }, { children: ["To run Sketch you\u2019ll need a macOS device that our", ' ', jsx(ExternalLink, Object.assign({ href: "http://sketch.com/redirect/web/system-requirements" }, { children: "system requirements" })), "."] }))), jsx(Line, {}), jsxs(TextSignUp, { children: ["Already have a Sketch account?", ' ', jsx(Link, Object.assign({ variant: "primary", to: {
                                        pathname: routes.SIGN_IN.create({}),
                                        state,
                                    }, isUnderlined: true }, { children: "Sign in" }))] })] })] })) })));
};
const overriddenThemeFor = (theme) => theme;

export { BaseSignUpView as default };
