import { __rest } from 'tslib';
import { jsx } from 'react/jsx-runtime';
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Portal } from 'react-portal';
import { Transition } from 'react-transition-group';
import { DropdownStyleWrapper, DropdownWrapper } from './ResponsiveDropdown.styles.js';

const IS_CYPRESS_TESTING = window.Cypress;
const STATUS_TO_CLASSNAMES = {
    unmounted: '',
    exited: 'dropdown-exit-active',
    entering: 'dropdown-enter',
    entered: 'dropdown-enter-active',
    exiting: 'dropdown-exit',
};
/**
 * This component has the visual representation of a dropdown
 * when rendered using the "useResponsiveDropdown" hook
 *
 * It has been implemented using as foundation the StatelessDropdown code
 * https://github.com/sketch-hq/cloud-frontend/blob/7c39ff49b022415ac7a1ec34d1a73bb160a45578/src/components/Dropdown/Dropdown.tsx#L77
 *
 * This component is in charge of:
 * - Rendering in a portal (if needed)
 * - Hiding if outside of the viewport (if needed)
 * - Animating the entry/exit of the dropdown
 */
const DropdownContainer = React.forwardRef(function DropdownContainer(props, externalRef) {
    const { visible, children, update, portal, onOutsideWindowHide, hideWhenOutsideWindow, dropdownStyle } = props, divProps = __rest(props, ["visible", "children", "update", "portal", "onOutsideWindowHide", "hideWhenOutsideWindow", "dropdownStyle"]);
    const [hasEntered, setHasEntered] = useState(false);
    const { ref, inView } = useInView({
        skip: !hideWhenOutsideWindow || !hasEntered,
        threshold: 0.75,
        initialInView: true,
    });
    /**
     * We call "onOutsideWindowHide" when the popover
     * is outside of view but we should make sure
     * first that is not opening still or animating
     */
    useEffect(() => {
        !inView && onOutsideWindowHide();
    }, [onOutsideWindowHide, inView]);
    const content = (jsx(Transition, Object.assign({ timeout: {
            appear: 0,
            enter: 0,
            // We decided to remove all delays when opening the dropdown
            // however, a "exit: 0" or "timeout={0}" is causing issues with Cypress
            // tests. A value of 100 is needed for Cypress to become stable
            exit: IS_CYPRESS_TESTING ? 100 : 0,
        }, mountOnEnter: true, unmountOnExit: true, in: visible, onExited: () => setHasEntered(false), onEntered: () => setHasEntered(true), classNames: "dropdown" }, { children: status => (jsx(DropdownStyleWrapper, Object.assign({ ref: externalRef }, divProps, { children: jsx(DropdownWrapper, Object.assign({ ref: ref, className: STATUS_TO_CLASSNAMES[status], "data-testid": "dropdown-options", style: dropdownStyle }, { children: children })) }))) })));
    if (portal) {
        return jsx(Portal, { children: content });
    }
    return content;
});

export { DropdownContainer };
