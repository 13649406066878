import { jsx, jsxs } from 'react/jsx-runtime';
import styled from 'styled-components';
import { ReactComponent as ReactComponent$2 } from '@sketch/icons/cross-circle-18';
import { ReactComponent } from '@sketch/icons/exclamation-mark-16';
import { ReactComponent as ReactComponent$1 } from '@sketch/icons/information-16';
import { WorkspaceIcon, Wrapper, CloseButtonUpsell, CloseButton, IconWrapper, Text } from './Banner.styles.js';

const Icon = ({ type }) => {
    if (type === 'error')
        return jsx(ReactComponent, {});
    if (type === 'upsell')
        return jsx(WorkspaceIcon, {});
    return jsx(ReactComponent$1, {});
};
const BannerUnstyled = props => {
    const { className, children, type, dismissible, onDismiss, showIcon = true, } = props;
    if (type === 'custom') {
        return (jsx(Wrapper, Object.assign({ className: className, "data-testid": `banner-${type}`, role: "alert", "$type": type }, { children: children })));
    }
    const CloseButton$1 = type === 'upsell' ? CloseButtonUpsell : CloseButton;
    return (jsxs(Wrapper, Object.assign({ className: className, "data-testid": `banner-${type}`, role: "alert", "$type": type }, { children: [showIcon && (jsx(IconWrapper, { children: jsx(Icon, { type: type }) })), jsx(Text, { children: children }), dismissible && (jsxs(CloseButton$1, Object.assign({ onClick: onDismiss }, { children: [jsx("span", Object.assign({ className: "sr-only" }, { children: "Close banner" })), jsx(ReactComponent$2, { "aria-hidden": true })] })))] })));
};
const Banner = styled(BannerUnstyled) ``;

export { Banner };
