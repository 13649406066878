import { useEffect, useState, useRef } from 'react'
import { dataIdFromObject } from '@sketch/graphql-cache'
import { handleFetchMore } from '@sketch/components'

import { useDebounceValue } from '@sketch/utils'

import {
  useGetWorkspaceMembershipsLazyQuery,
  GetWorkspaceMembershipsQuery,
  GetWorkspaceMembershipsQueryVariables,
} from '@sketch/gql-types'
import { useWorkspaceSettingsPeopleContext } from '../../../views/WorkspaceSettingsView/WorkspaceSettingsPeopleContext'
/**
 * Prevent the MEMBERSHIP_PATH To have incoherent pathname that don't belong to the query
 */
type QueryMembershipPath = [
  keyof GetWorkspaceMembershipsQuery,
  keyof GetWorkspaceMembershipsQuery['workspace'],
  keyof GetWorkspaceMembershipsQuery['workspace']['memberships'],
  keyof NonNullable<
    GetWorkspaceMembershipsQuery['workspace']['memberships']['list']
  >
]

type TotalUserCountType = {
  totalMemberships: number | null
  totalGuests: number | null
  totalMembers: number | null
}

type AccessLevelTypes = GetWorkspaceMembershipsQueryVariables['accessLevel']
type RoleTypes = GetWorkspaceMembershipsQueryVariables['role']
type ExcludeRolesFilter = GetWorkspaceMembershipsQueryVariables['excludeRoles']
type WorkspaceMembershipOrderType = GetWorkspaceMembershipsQueryVariables['order']

const MEMBERSHIP_PATH: QueryMembershipPath = [
  'workspace',
  'memberships',
  'list',
  'entries',
]

const DEFAULT_NUMBER_SKELETON_ROWS = 3

/*
 * useMembershipsPanel.
 *
 * Handles all the logic to render the Memberships Panel properly.
 */
const useMembershipsPanel = (workspaceId: string) => {
  const previousNumberOfMemberRows = useRef<number>(
    DEFAULT_NUMBER_SKELETON_ROWS
  )
  const userCount = useRef<TotalUserCountType>({
    totalMemberships: null,
    totalGuests: null,
    totalMembers: null,
  })

  const {
    order,
    orderDirection,
    setOrder,
    setOrderDirection,
    setPersistSorting,
  } = useWorkspaceSettingsPeopleContext()

  const [filter, setFilter] = useState<string | undefined>(undefined)
  const [roleFilter, setRoleFilter] = useState<RoleTypes>(undefined)
  const [accessLevelFilter, setAccessLevelFilter] = useState<AccessLevelTypes>(
    undefined
  )
  const [
    excludeRolesFilter,
    setExcludeRolesFilter,
  ] = useState<ExcludeRolesFilter>(undefined)

  const filterDebounced = useDebounceValue(filter, 500)

  const [
    fetchWorkspaceMemberships,
    { loading, error, data, fetchMore: fetchMoreRaw },
  ] = useGetWorkspaceMembershipsLazyQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  /* Fetch the Workspace memberships everytime the filters change */
  useEffect(() => {
    fetchWorkspaceMemberships({
      variables: {
        workspaceId,
        /**
         * when filter === '', apollo marks it as a different query
         * when filter === undefined
         * This results in two HTTP request made to get the same data
         * Therefore when filter is falsy, set it to undefined
         */
        filter: filterDebounced || undefined,
        role: roleFilter,
        accessLevel: accessLevelFilter,
        excludeRoles: excludeRolesFilter,
        order,
        orderDirection,
      },
    })
  }, [
    fetchWorkspaceMemberships,
    workspaceId,
    filterDebounced,
    roleFilter,
    accessLevelFilter,
    excludeRolesFilter,
    order,
    orderDirection,
  ])

  // When selecting "Guests" filter, we reset the access level filter
  // in order to avoid a filter of guests + editor which would
  // always show an empty table
  useEffect(() => {
    if (roleFilter === 'GUEST') {
      setAccessLevelFilter(undefined)
    }
  }, [roleFilter])

  /*
   * To make the loading experience with the Skeleton rows smoother,
   * we are counting the number of rows the table has, before the loading happens,
   * and feeding this number to the SkeletonMembersTable, this way the number of
   * skeleton rows will be the same as the number of members, making a smoother transition
   */
  useEffect(() => {
    const rowTestId = '[data-testid="members-table-row"]' // Test ID for each table row
    const numberCurrentRows = document.querySelectorAll(rowTestId).length || 1 // No members found

    previousNumberOfMemberRows.current = numberCurrentRows
  }, [roleFilter, accessLevelFilter, order, orderDirection])

  const onSortingChange = (sortKey: WorkspaceMembershipOrderType) => {
    setOrder(sortKey)
    setOrderDirection(orderDirection === 'ASC' ? 'DESC' : 'ASC')
    setPersistSorting({
      key: sortKey,
      order: orderDirection === 'ASC' ? 'DESC' : 'ASC',
    })
  }

  /* Prepare fetch more function*/
  const fetchMore = handleFetchMore<
    GetWorkspaceMembershipsQuery,
    GetWorkspaceMembershipsQueryVariables
  >(fetchMoreRaw, MEMBERSHIP_PATH, {
    dataIdFromObject,
    after: data?.workspace?.memberships?.list?.meta.after,
  })

  return {
    loading,
    error,
    data,
    fetchMore,
    userCount,
    filters: {
      filter,
      setFilter,
      roleFilter,
      setRoleFilter,
      accessLevelFilter,
      setAccessLevelFilter,
      setExcludeRolesFilter,
      onSortingChange,
      order,
      orderDirection,
    },
    previousNumberOfMemberRows,
  }
}

export type UseMembershipsPanelResult = ReturnType<typeof useMembershipsPanel>
export type MembershipsPanelFilters = UseMembershipsPanelResult['filters']

export default useMembershipsPanel
