import React from 'react'

import { routes, useFlag } from '@sketch/modules-common'
import { ProjectFragment, ShareListItemFragment } from '@sketch/gql-types'

import {
  Button,
  Separator,
  Modal,
  ModalInjectedProps,
  ToastLink,
} from '@sketch/components'

import ShareAccessList from '../../../shares/components/ShareAccessList'

import { useToast } from '@sketch/toasts'
import { useMoveShareToProject } from '../../operations'

import {
  Bold,
  CenterIconText,
  FolderIcon,
  StyledBanner,
} from './ConfirmMoveModal.styles'

interface ConfirmMoveModalProps extends ModalInjectedProps {
  workspaceIdentifier: string
  newProject: ProjectFragment
  share: ShareListItemFragment
  isDraftDocument?: boolean
}

function ConfirmMoveModal(props: ConfirmMoveModalProps) {
  // Remove FF when its released
  // https://github.com/sketch-hq/Cloud/issues/18785
  const hasMyDraftsRevampFFEnabled = useFlag('my-drafts-revamp')

  const {
    hideModal,
    newProject,
    share,
    isDraftDocument,
    workspaceIdentifier,
  } = props
  const { showToast } = useToast()

  const [moveShare, { loading }] = useMoveShareToProject({
    onCompleted: project => {
      showToast(
        <>
          Document moved to &ldquo;
          <ToastLink
            to={routes.WORKSPACE_PROJECT.create({
              projectId: project.identifier,
              workspaceId: workspaceIdentifier,
            })}
          >
            {project.name}
          </ToastLink>
          &rdquo;
        </>
      )
      hideModal()
    },
    onError: errorMessage => {
      showToast(errorMessage, 'negative')
    },
  })

  return (
    <Modal
      title="Move Document?"
      onCancel={hideModal}
      data-testid="confirm-move-modal"
    >
      <Modal.Body>
        <CenterIconText>
          to <FolderIcon />{' '}
          <Bold>
            <span data-testid="project-name">{newProject.name}</span>
          </Bold>
        </CenterIconText>

        {/* This message can be removed when we remove the "my-drafts-revamp" FF */}
        {!hasMyDraftsRevampFFEnabled && isDraftDocument && (
          <>
            <StyledBanner type="warning" showIcon={false}>
              Documents cannot be moved back to My Drafts.
            </StyledBanner>
            <Separator mb={6} />
          </>
        )}
        <ShareAccessList
          shareIdentifier={share.identifier}
          destination={{ project: newProject }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal}>Cancel</Button>
        <Button
          loading={loading}
          onClick={() =>
            moveShare({
              shareId: share.identifier,
              projectId: newProject.identifier,
            })
          }
          variant="primary"
        >
          Move Document
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ConfirmMoveModal }
