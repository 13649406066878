import { useGetWorkspacesQuery } from '@sketch/gql-types';

const getWorkspaces = (data) => {
    if (!(data === null || data === void 0 ? void 0 : data.me)) {
        return undefined;
    }
    const workspaces = [
        ...(data.me.personalWorkspace ? [data.me.personalWorkspace] : []),
        ...data.me.workspaces,
    ];
    return workspaces;
};
const useGetWorkspaces = (props) => {
    const result = useGetWorkspacesQuery(Object.assign({}, props));
    const workspaces = getWorkspaces(result.data);
    return Object.assign(Object.assign({}, result), { workspaces });
};

export { useGetWorkspaces };
