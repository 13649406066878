import React, { FC, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import {
  useModalContext,
  useStripe,
  OrderedBreadcrumbs,
} from '@sketch/components'

import {
  DynamicLoadingPage,
  IndexLayoutErrorOld,
  routes,
  useQueryParams,
} from '@sketch/modules-common'

import { WorkspaceSubscribePaymentDetails } from '../../pages'
import PartnerSubscriptionDoneModal from '../../modals/PartnerSubscriptionDoneModal'
import PartnerExpiredWorkspaceError from '../../components/PartnerExpiredWorkspaceError'

import useWorkspaceSubscribeState from '../WorkspaceSubscribeView/WorkspaceSubscribeView.hooks'
import { isWorkspaceSubscriptionActive } from '../../utils'

import {
  WorkspaceSubscribeLayout,
  BreadcrumbsWrapper,
} from '../../views/WorkspaceSubscribeView/WorkspaceSubscribeView.styles'

// GQL
import { usePartnerSubscribeInformation } from '../../operations'

import {
  useAcceptPartnershipInviteMutation,
  useCreatePartnerWorkspaceMutation,
  useGetPendingWorkspaceMembershipQuery,
  PublicWorkspaceMinimalFragment,
  useGetAvailablePlansQuery,
  BillingPlanFragment,
  GetWorkspacesDocument,
} from '@sketch/gql-types'

import config from '@sketch/env-config'
import { SubmitInvoiceValues } from '../../pages/WorkspaceSubscribePaymentDetails/WorkspaceSubscribePaymentDetails'

type WorkspaceCreateType = {
  name: string
  logo: File | undefined
  ownerEmail: string
}

interface WorkspaceBillingViewProps {
  workspace?: PublicWorkspaceMinimalFragment
  token?: string
  workspaceState?: WorkspaceCreateType
}

const PARTNER_WORKSPACE_CRUMBS = [
  { content: 'Create Workspace' },
  { content: 'Set Up a Subscription' },
]

// The form has two modes of operation:
// Token defined, workspaceState undefined: We are accepting an invite
// Token undefined, workspaceSate defined: A partner is creating a new workspace
const WorkspaceBillingView: FC<WorkspaceBillingViewProps> = ({
  workspace,
  token,
  workspaceState,
}) => {
  const history = useHistory()
  const { showModal, hideModal } = useModalContext()

  // We already made sure the `workspace.customer` exists in "WorkspaceBillingViewGuard"
  const customer = workspace?.customer

  // Load stripe in order previously
  const { load: loadStripe, status: stripeStatus } = useStripe()

  useEffect(() => {
    loadStripe()
  }, [loadStripe])

  const [state, triggers] = useWorkspaceSubscribeState()
  const {
    loading: loadingPlans,
    error: errorPlans,
    data: plans,
  } = useGetAvailablePlansQuery()

  const { loading, data, error } = usePartnerSubscribeInformation(
    customer?.identifier
  )

  const [acceptPartnershipInvite] = useAcceptPartnershipInviteMutation({
    onCompleted: () => {
      if (workspace) {
        history.push(
          routes.WORKSPACE_SETTINGS_PEOPLE.create({
            workspaceId: workspace.identifier,
          })
        )

        showModal(
          PartnerSubscriptionDoneModal,
          { onHide: hideModal, workspaceName: workspace.name },
          { closeOnRouteChange: false }
        )
      }
    },
    onError: 'show-toast',
  })
  const [createPartnerWorkspace] = useCreatePartnerWorkspaceMutation({
    onCompleted: props => {
      history.push(
        routes.WORKSPACE_SETTINGS_PEOPLE.create({
          workspaceId: props.createPartnerWorkspace?.workspace?.identifier,
        })
      )

      if (workspaceState) {
        showModal(
          PartnerSubscriptionDoneModal,
          { onHide: hideModal, workspaceName: workspaceState.name },
          { closeOnRouteChange: false }
        )
      }
    },
    refetchQueries: [{ query: GetWorkspacesDocument }],
    onError: 'show-toast',
  })

  const subscription = data?.customer?.subscription
  useEffect(() => {
    const numberOfScheduledSeats = subscription?.scheduledSeatsTotal
    const numberOfCurrentSeats = subscription?.currentSeatsTotal
    const numberOfEditors = numberOfScheduledSeats || numberOfCurrentSeats

    if (!state.editors && numberOfEditors) {
      triggers.setEditors(numberOfEditors)
    }
    if (!token) {
      triggers.setEditors(state.editors || 1)
    }
  }, [subscription, triggers, state.editors, token])

  if (loading || loadingPlans || stripeStatus === 'pending' || !state.editors) {
    return <DynamicLoadingPage />
  }

  const isSubscriptionActive =
    subscription && isWorkspaceSubscriptionActive(subscription?.status)

  if (!isSubscriptionActive && token) {
    return <PartnerExpiredWorkspaceError />
  }

  if (error || errorPlans || stripeStatus === 'error') {
    return <IndexLayoutErrorOld />
  }

  const plansId = 'pro_plans_ids'
  let partnerYearlyPlan = subscription?.currentPlan

  if (!partnerYearlyPlan || partnerYearlyPlan?.type === 'MONTHLY') {
    partnerYearlyPlan = plans?.availablePlans?.find(
      item => item.id === config[plansId].yearly
    ) as BillingPlanFragment
  }

  return (
    <WorkspaceSubscribeLayout expand headerLink="workspace-settings">
      {!token && (
        <BreadcrumbsWrapper>
          <OrderedBreadcrumbs
            showAllInMobile
            crumbs={PARTNER_WORKSPACE_CRUMBS}
            currentCrumb={1}
          />
        </BreadcrumbsWrapper>
      )}
      <WorkspaceSubscribePaymentDetails
        paymentMethod="invoice"
        variant="partner"
        title="Set Up a Subscription"
        isCreatingWorkspace={!!workspaceState}
        workspace={{
          name: workspace?.name || '',
          avatar: workspace?.avatar?.small || '',
        }}
        billingInfo={{
          address: data?.partnerBillingDetails?.address?.line1,
          city: data?.partnerBillingDetails?.address?.city,
          postalCode: data?.partnerBillingDetails?.address?.postalCode,
          state: data?.partnerBillingDetails?.address?.state || '',
          country: data?.partnerBillingDetails?.address?.country,
          email: data?.partnerBillingDetails?.email,
          name: data?.partnerBillingDetails?.name,
          taxId: data?.partnerBillingDetails?.taxId || undefined,
          tos: false,
          shippingAddress: '',
          shippingName: '',
          shippingState: '',
          shippingPostalCode: '',
          shippingCountry: 'US',
        }}
        billSimulation={{
          plan: partnerYearlyPlan!,
          trialEnd: '',
          numberEditors: state.editors,
          onEditorsChange: triggers.setEditors,
          numberExtraSeats: 0,
          showDiscountInput: false,
          discountCode: state.discountCode,
          onValidDiscountCode: triggers.applyDiscountCode,
          onClearDiscountCode: triggers.clearDiscountCode,
          discountError: state.discountError,
          onUpdateDiscountError: triggers.updateDiscountError,
        }}
        onSubmit={async values => {
          if (workspaceState) {
            await createPartnerWorkspace({
              variables: {
                input: {
                  seats: state.editors as number,
                  tosAgreed: values.tos as boolean,
                  ownerEmail: workspaceState.ownerEmail,
                  avatar: workspaceState.logo,
                  name: workspaceState.name,
                  shipping: {
                    name: (values as SubmitInvoiceValues).shippingName,
                    address: {
                      state: (values as SubmitInvoiceValues).shippingState,
                      line1: (values as SubmitInvoiceValues).shippingAddress,
                      city: (values as SubmitInvoiceValues).shippingCity,
                      postalCode: (values as SubmitInvoiceValues)
                        .shippingPostalCode,
                      country: (values as SubmitInvoiceValues).shippingCountry,
                    },
                  },
                },
              },
            })
          } else if (token && state.editors) {
            await acceptPartnershipInvite({
              variables: {
                membershipToken: token,
                seats: state.editors,
                tosAgreed: values.tos,
                shipping: {
                  name: (values as SubmitInvoiceValues).shippingName,
                  address: {
                    state: (values as SubmitInvoiceValues).shippingState,
                    line1: (values as SubmitInvoiceValues).shippingAddress,
                    city: (values as SubmitInvoiceValues).shippingCity,
                    postalCode: (values as SubmitInvoiceValues)
                      .shippingPostalCode,
                    country: (values as SubmitInvoiceValues).shippingCountry,
                  },
                },
              },
            })
          }
        }}
      />
    </WorkspaceSubscribeLayout>
  )
}

const WorkspaceBillingViewGuard: FC = () => {
  const { token } = useQueryParams<'WORKSPACE_SUBSCRIBE_PARTNER'>()
  const location = useLocation<WorkspaceCreateType>()

  const { loading, data } = useGetPendingWorkspaceMembershipQuery({
    variables: { membershipToken: token || '', includeCustomer: true },
    skip: !token,
  })

  if (loading) return <DynamicLoadingPage />

  const workspace = data?.pendingWorkspaceMembership

  return (
    <WorkspaceBillingView
      workspace={workspace?.workspace}
      token={token}
      workspaceState={location.state}
    />
  )
}

export default WorkspaceBillingViewGuard
