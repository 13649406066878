import { __rest } from 'tslib';
import { useState, useEffect } from 'react';
import qs from 'query-string';
import debounce from 'debounce';

const DEBOUNCE_INTERVAL = 500;
const setSearchQueryDebounced = debounce((searchValue, history, previousQuery) => {
    // When the user clears a search we remove the search query param
    if (!searchValue && (previousQuery === null || previousQuery === void 0 ? void 0 : previousQuery.search)) {
        // Drop the search term but keep the other query parameters
        const { search: _ } = previousQuery, newQuery = __rest(previousQuery, ["search"]);
        history.push({ search: qs.stringify(newQuery) });
        return;
    }
    if (!searchValue && (previousQuery === null || previousQuery === void 0 ? void 0 : previousQuery.search)) {
        // If there's no search then we should clear the URL parameter
        const { search } = previousQuery, otherParameters = __rest(previousQuery, ["search"]);
        history.push({
            search: qs.stringify(otherParameters),
        });
    }
    else if (!searchValue) {
        // If there's no search then we should clear the URL parameter
        return;
    }
    history.push({
        search: qs.stringify(Object.assign(Object.assign({}, previousQuery), { search: searchValue })),
    });
}, DEBOUNCE_INTERVAL);
const useURLQuerySearch = (location, history) => {
    const queryArguments = qs.parse(location.search);
    const searchQuery = queryArguments.search || '';
    const [search, setSearchValue] = useState(searchQuery);
    const setSearchQuery = (searchValue) => {
        setSearchValue(searchValue);
        setSearchQueryDebounced(searchValue, history, queryArguments);
    };
    /**
     * Sync the internal search with the url search
     */
    useEffect(() => {
        setSearchValue(searchQuery);
    }, [searchQuery]);
    return [search, setSearchQuery, searchQuery];
};

export { useURLQuerySearch };
