import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { SKETCH_WEBSITE } from '@sketch/env-config';
import { useQueryParams, getIsForInAppPresentation, sourceToSsoSignInSource, IndexLayoutContent, IndexLayoutHeaderLink, IndexLayoutOldTitle, IndexLayoutOldSubtitle, routes } from '@sketch/modules-common';
import SsoSignInForm from './SsoSignInForm.js';
import { Wrapper, SsoInformationAndFaqWrapper, Line, StyledLink } from './SsoSignInForCloud.styles.js';

const SsoSignInForCloud = props => {
    var _a, _b;
    const { HeaderPortal } = props;
    const { state } = props.location;
    const { source = 'cloud', team, shortname } = useQueryParams();
    const isForInAppPresentation = getIsForInAppPresentation();
    const teamName = team || shortname;
    const formSource = isForInAppPresentation
        ? 'sketch'
        : (_a = sourceToSsoSignInSource[source]) !== null && _a !== void 0 ? _a : 'cloud';
    const target = isForInAppPresentation ? '_self' : '_blank';
    return (jsxs(IndexLayoutContent, Object.assign({ center: "horizontal", marginTop: true, paddingHorizontal: true }, { children: [jsx(HeaderPortal, { children: jsx(IndexLayoutHeaderLink, {}) }), jsxs(Wrapper, { children: [jsx(IndexLayoutOldTitle, { children: "Enter SSO Shortname" }), jsx(IndexLayoutOldSubtitle, { children: "Enter your Workspace's single sign-on (SSO) name" }), jsx(SsoSignInForm, { source: formSource, teamName: teamName, from: (_b = state === null || state === void 0 ? void 0 : state.from) === null || _b === void 0 ? void 0 : _b.pathname }), jsxs(SsoInformationAndFaqWrapper, { children: [jsx(Line, {}), jsx(StyledLink, Object.assign({ href: `${SKETCH_WEBSITE}/redirect/web/sso`, target: target, rel: "noopener noreferrer", variant: "primary", isUnderlined: true, external: true }, { children: "How Single Sign-On (SSO) works" })), !isForInAppPresentation && (jsx(Fragment, { children: jsx(StyledLink, Object.assign({ variant: "primary", isUnderlined: true, to: { pathname: routes.SIGN_IN.create({}), state } }, { children: "Sign in with email and password" })) }))] })] })] })));
};

export { SsoSignInForCloud };
