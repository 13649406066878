const selectors = {
    contentScrollable: '[data-content-scrollable]',
    sidebarLeft: '[data-sidebar-left]',
    // Related to mobile header
    mobileHeaderLeft: '[data-mobile-header-left]',
    workspaceDropdown: '[data-workspace-dropdown-navbar]',
    navbarSectionTitle: '[data-navbar-section-title]',
};

export { selectors };
