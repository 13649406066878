import styled, { css } from 'styled-components';

const activeStyles = css `
  &&& {
    ${({ theme }) => css `
      background-color: ${theme.colors.sketch.F};
      color: ${theme.colors.sketch.A};
    `}
  }
`;
const hoverStyles = css `
  ${({ theme }) => css `
    background-color: ${theme.colors.state.hover};
    color: ${theme.colors.foreground.secondary.A};
  `}
`;
const disabledStyles = css `
  color: ${({ theme }) => theme.colors.state.disabled};
  pointer-events: none;
`;
const Text = styled.span `
  padding: 6px 12px; /* stylelint-disable-line scales/space */
  font-size: 0.8125rem;
  line-height: 1.4;
`;
const IconWrapper = styled.span `
  display: inline-block;
  padding: 4px 8px;
  line-height: 0;
`;
const Icon = styled.svg `
  width: 24px;
  height: 24px;
`;
const Wrapper = styled.span(({ theme, $active, $disabled }) => css `
    display: inline-flex;
    cursor: pointer;
    height: 32px;

    color: ${theme.colors.foreground.secondary.D};
    border-radius: ${theme.radii.large};

    transition: background ${theme.transitions.duration[0]} ease-in-out,
      color ${theme.transitions.duration[0]} ease-in-out;

    ${$active && activeStyles}

    &.active {
      ${activeStyles}
    }

    &:hover {
      ${hoverStyles}
    }

    &:disabled {
      ${disabledStyles}
    }

    ${$disabled && disabledStyles}
  `);

export { Icon, IconWrapper, Text, Wrapper, activeStyles, disabledStyles, hoverStyles };
