import React, { useState } from 'react'
import { isApolloError } from 'apollo-client'

import { SKETCH_WEBSITE } from '@sketch/env-config'

import { MAX_SEATS_VALUE } from '../../constants'

import { useToast } from '@sketch/toasts'
import {
  Button,
  Pluralize,
  pluralize,
  Stepper,
  Modal,
  ModalInjectedProps,
  useStripe,
} from '@sketch/components'
import Discount from '../../components/Discount'

import {
  SummaryHeader,
  SummaryLine,
  SummaryTotalLine,
  SummaryError,
  SummaryTotalDescribed,
  SummaryPaymentMethod,
  SummaryDiscountLine,
  ModalWarning,
  ModalScheduleDisclaimer,
  CollapsibleSection,
  SummaryProrated,
} from '../../components'

import { castError, dateFormat, useDebounceValue } from '@sketch/utils'
import {
  confirmPendingSetupIntent,
  getAppliedDiscount,
  ERROR_MESSAGE,
  getPaymentTypeByProratedAmounts,
} from '../../utils'

import {
  StepperSection,
  Paragraph,
  BoldParagraph,
  Strong,
  SeatsMaximumWarning,
  DiscountWrapper,
} from './AddSeatsModal.styles'

import {
  BillingPlanFragment,
  PaymentDetailsFragment,
  BillingSeatsInfoFragment,
  useGetSeatsUpdateBillingSimulationQuery,
  useUpdateBillingSeatsMutation,
  useApplyCouponMutation,
  CouponFragment,
} from '@sketch/gql-types'

interface AddSeatsModalProps extends ModalInjectedProps {
  workspaceId: string
  customerId: string
  nextBillingCycleDate?: string
  seats: BillingSeatsInfoFragment
  isOnTrial: boolean
  plan: BillingPlanFragment
  paymentDetails?: PaymentDetailsFragment
  isScheduledToBeCancelled?: boolean
  variant?: 'default' | 'partner'
}

type DiscountError =
  | {
      code: string
      reason: string
    }
  | undefined

const MIN_SEATS_VALUE = 1

export const AddSeatsModal: React.FC<AddSeatsModalProps> = props => {
  const {
    hideModal,
    nextBillingCycleDate: nextBillingCycleDateString,
    customerId,
    workspaceId,
    plan,
    isOnTrial,
    paymentDetails,
    seats,
    isScheduledToBeCancelled,
    variant = 'partner',
  } = props

  const { load: loadStripe } = useStripe()
  const { showToast } = useToast()
  const [newSeats, setNewSeats] = useState(MIN_SEATS_VALUE)
  const [isLoading, setIsLoading] = useState(false)
  const [discountError, setDiscountError] = useState<DiscountError>(undefined)
  const [isDiscountAlreadyApplied, setIsDiscountAlreadyApplied] = useState<
    boolean | undefined
  >(undefined)

  const debouncedSeats = useDebounceValue(newSeats, 300)

  const totalNumberOfSeats = seats.currentSeatsTotal + newSeats
  const canSkipSCA = isOnTrial && isScheduledToBeCancelled

  const {
    loading: isSummaryLoading,
    error,
    data,
    refetch,
  } = useGetSeatsUpdateBillingSimulationQuery({
    fetchPolicy: 'network-only',
    variables: {
      totalSeats: totalNumberOfSeats,
      customerId,
    },
    onCompleted: data => {
      if (isDiscountAlreadyApplied === undefined) {
        const { seatsUpdateBillingSimulation } = data

        setIsDiscountAlreadyApplied(!!seatsUpdateBillingSimulation.couponInfo)
      }
    },
  })

  const [
    updateSeats,
    { loading: seatsLoading },
  ] = useUpdateBillingSeatsMutation({
    variables: {
      input: {
        numberOfSeats: totalNumberOfSeats,
        billingPeriod: 'CURRENT',
        workspaceIdentifier: workspaceId,
      },
    },
    onError: 'show-toast',
  })

  const [
    applyCoupon,
    { loading: isLoadingApplyCoupon },
  ] = useApplyCouponMutation({ onError: 'unsafe-throw-exception' })

  const appliedDiscount = getAppliedDiscount(
    data?.seatsUpdateBillingSimulation?.couponInfo || undefined
  )

  // TEMPORARILY DISABLED DISCOUNTS UI
  // https://github.com/sketch-hq/cloud-frontend/pull/6249
  // const isDiscountFieldVisible =
  //   hasPaymentDetails(paymentDetails) && variant !== 'partner' && false
  const isDiscountFieldVisible = false

  const handleApplyDiscount = async (discountCode: string) => {
    try {
      await applyCoupon({
        variables: {
          customerId,
          promotionCode: discountCode,
        },
      })

      refetch()
      setDiscountError(undefined)
    } catch (e) {
      const error = castError(e)
      if (isApolloError(error)) {
        const code = error?.graphQLErrors[0]?.extensions
          ?.code as CouponFragment['errorCode']

        const isValidCode = !!code && Object.keys(ERROR_MESSAGE).includes(code)

        setDiscountError({
          code: discountCode,
          reason: isValidCode ? ERROR_MESSAGE[code!] : ' is invalid',
        })
      } else {
        setDiscountError({
          code: '',
          reason: error.message,
        })
      }
    }
  }

  const handleUpdateSeats = async () => {
    setIsLoading(true)

    try {
      const { data, error } = await updateSeats()

      const { pendingScaToken } = data?.updateBillingSeats || {}

      /**
       * If "pendingScaToken" is present on the payload we need to do the
       * challenge with stripe to make sure the card is not INCOMPLETE
       */
      if (pendingScaToken && !canSkipSCA) {
        const stripe = await loadStripe()

        await confirmPendingSetupIntent(stripe, pendingScaToken)
      }

      if (!error) {
        showToast(
          `Editor ${pluralize('Seat', 'Seats', newSeats)} successfully added`,
          'positive'
        )
      }
      hideModal()
    } catch (e) {
      const error = castError(e)
      showToast(error.message, 'negative')
    }

    setIsLoading(false)
  }

  const loading = isSummaryLoading || newSeats !== debouncedSeats || isLoading
  const nextBillingCycleDate = nextBillingCycleDateString
    ? new Date(nextBillingCycleDateString)
    : null
  const showMaxSeatsWarning = newSeats === MAX_SEATS_VALUE

  const { extraSeatsAmount, prorated } =
    data?.seatsUpdateBillingSimulation || {}

  const paymentType = getPaymentTypeByProratedAmounts(
    prorated?.chargeAmount,
    prorated?.creditsAmount
  )

  const extraCopy = nextBillingCycleDate
    ? ` until the next billing date on ${dateFormat(
        nextBillingCycleDate,
        'en-GB'
      )}.`
    : `.`

  return (
    <Modal onCancel={hideModal}>
      <Modal.Header>Add Editor Seats</Modal.Header>
      <Modal.Body>
        <Paragraph>
          Your Workspace currently has{' '}
          <Strong>
            {seats.currentSeatsTotal} Editor{' '}
            <Pluralize
              count={seats.currentSeatsTotal}
              singular="seat"
              plural="seats"
              wrapperElement={React.Fragment}
            />
          </Strong>
          .
          <br />
          {`Seats you add here are available immediately and are billed on a pro-rata basis${extraCopy}`}
        </Paragraph>
        <br />
        <StepperSection>
          <BoldParagraph>Add Seats</BoldParagraph>
          <Stepper
            value={newSeats}
            onChange={setNewSeats}
            min={MIN_SEATS_VALUE}
            max={MAX_SEATS_VALUE}
          />
        </StepperSection>
        <CollapsibleSection collapsed={!showMaxSeatsWarning}>
          <br />
          {showMaxSeatsWarning && (
            <SeatsMaximumWarning>
              If you need more seats please contact our{' '}
              <a
                href={`${SKETCH_WEBSITE}/support/contact/`}
                rel="noopener noreferrer"
                target="_blank"
              >
                customer support team
              </a>
              .
            </SeatsMaximumWarning>
          )}
        </CollapsibleSection>
      </Modal.Body>
      <Modal.Body>
        {error ? (
          <SummaryError refetch={() => refetch()} />
        ) : (
          <>
            <SummaryHeader
              plan={plan}
              paymentMethod={
                // Wait for the BE response to show the proper payment method
                paymentDetails &&
                prorated && (
                  <SummaryPaymentMethod
                    chargeMethod={paymentDetails}
                    paymentType={paymentType}
                  />
                )
              }
            />
            <SummaryLine
              description={`${newSeats} Seats`}
              value={extraSeatsAmount!}
              loading={loading}
            />
            {isDiscountFieldVisible && (
              <DiscountWrapper>
                <Discount
                  onApplyDiscountCode={handleApplyDiscount}
                  error={discountError}
                  isDisabled={isLoadingApplyCoupon}
                  isLoading={isLoadingApplyCoupon}
                />
              </DiscountWrapper>
            )}
            {appliedDiscount && (
              <SummaryDiscountLine
                appliedDiscount={appliedDiscount}
                isAlreadyApplied={isDiscountAlreadyApplied}
                variant={variant}
              />
            )}
            {prorated && (
              <SummaryProrated
                daysElapsedInCycle={prorated.daysElapsedInCycle}
                refundAmountForNewSeats={
                  prorated.proratedRefundAmountForNewSeats
                }
              />
            )}
            <SummaryTotalLine
              description={
                isOnTrial ? 'Total Due During Trial' : 'Total due today'
              }
              value={prorated?.amount ?? 0}
              loading={loading}
            />
            <SummaryTotalDescribed
              charge={prorated?.chargeAmount}
              credit={prorated?.creditsAmount}
            />
            <ModalWarning
              title="What happens after the trial period?"
              description="If you continue with a subscription after your trial ends, you’ll be charged for Editors and any Unused Seats you have.
You won’t be charged during your trial period."
              show={isOnTrial}
            />
            {!!seats.scheduledSeatsTotal && !!nextBillingCycleDateString && (
              <ModalScheduleDisclaimer
                hideModal={hideModal}
                nextBillingCycleDate={nextBillingCycleDateString}
                scheduledSeats={seats.scheduledSeatsTotal}
                workspaceId={workspaceId}
              />
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={hideModal}
          disabled={seatsLoading || isLoading}
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          disabled={loading}
          loading={seatsLoading || isLoading}
          onClick={handleUpdateSeats}
        >
          {isOnTrial ? 'Add Seats' : 'Pay and Add seats'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
