import React, { useMemo } from 'react'
import { useLocation, useRouteMatch } from 'react-router'

import {
  Tooltip,
  Flex,
  WorkspaceLogo,
  useForTablet,
  LoadingPlaceholder,
  useResponsiveDropdown,
} from '@sketch/components'

import {
  DropdownContentContainer,
  DropdownDivider,
  DropdownLinkItem,
  DropdownTarget,
  SignedOutLabel,
  StyledSsoTag as SsoTag,
  WorkspaceName,
  StyledExpiredLabel as ExpiredLabel,
  DropdownHeader,
  DrodpwonItemSubtitle,
  DropdownInnerContainer,
  CreateWorkspaceButtonWrapper,
  StyledTrialPill,
  DropdownLinkItemMainContent,
} from './WorkspacesDropdown.styles'

import {
  useGetInitialUserQuery,
  WorkspaceMinimalFragment,
  WorkspaceMinimalWithBillingStatusFragment,
} from '@sketch/gql-types'

import {
  useUserSignedIn,
  routes,
  useFlag,
  useGetWorkspaces,
} from '@sketch/modules-common'

import { useSignInToUnauthorizedWorkspace } from '../../../sso/operations'
import { useIsNodeContentTruncated } from '@sketch/utils'

import { IS_EMBEDDED } from '@sketch/constants'
import {
  isPersonalWorkspace,
  getPersonalWorkspace,
  getMemberWorkspaces,
  getGuestWorkspaces,
  useCanSubscribeViaStripe,
} from '../../utils'
import { useWorkspaceDropdownStatuses } from './utils'
import { isBillingHidden } from '@sketch/env-config'

/**
 * TYPES
 */
export interface WorkspacesDropdownProps {
  workspace: WorkspaceMinimalFragment
  size?: 24 | 32
}

interface WorkspaceDropdownItemProps {
  selectedWorkspace: WorkspaceMinimalFragment
  workspace:
    | WorkspaceMinimalFragment
    | WorkspaceMinimalWithBillingStatusFragment
}

const WorkspaceDropdownItem = ({
  selectedWorkspace,
  workspace,
}: WorkspaceDropdownItemProps) => {
  const location = useLocation()
  const isWorkspaceRoute = useRouteMatch({
    path: routes.WORKSPACE.template(),
  })
  const isWorkspaceProjectRoute = useRouteMatch({
    path: routes.WORKSPACE_PROJECT.template(),
  })

  const { signIn } = useSignInToUnauthorizedWorkspace()
  const [
    isTextTruncated,
    truncatedNodeRef,
  ] = useIsNodeContentTruncated<HTMLDivElement>()
  const isTabletAndBigger = useForTablet()
  const workspaceDropdownStatuses = useWorkspaceDropdownStatuses(
    workspace,
    selectedWorkspace.identifier
  )
  const {
    isPartnerWorkspace,
    isGuestWorkspace,
    isAuthorizedWorkspace,
    isSelectedWorkspace,
    isWorkspaceActive,
    isSSOWorkspace,
    shouldShowTrialPill,
  } = workspaceDropdownStatuses
  const { identifier, avatar, name } = workspace

  // If the device is too narrow, the tooltip doesn't fit in the screen
  // and looks broken.
  const shouldShowTooltip =
    isTabletAndBigger && (isPartnerWorkspace || isTextTruncated)

  const isFinance = workspace.userRole === 'FINANCE'
  const isPartner = workspace.userRole === 'PARTNER'

  let workspaceRoute
  if (isFinance || isPartner) {
    // Finance and Partner role user does not have access to documents, so it should
    // start with workspace settings
    workspaceRoute = routes.WORKSPACE_SETTINGS_PEOPLE.create({
      workspaceId: identifier,
    })
  } else {
    // Maintain the pathname when changing between workspaces routes
    if (isWorkspaceRoute && !isWorkspaceProjectRoute) {
      workspaceRoute = location.pathname.replace(
        selectedWorkspace.identifier,
        identifier
      )
    } else {
      workspaceRoute = routes.WORKSPACE_SHARES.create({
        workspaceId: identifier,
      })
    }
  }

  // We hide the Personal Workspace option for Partners
  if (isPartner && isPersonalWorkspace(workspace)) {
    return null
  }

  return (
    <Tooltip
      content={isPartnerWorkspace ? identifier : name}
      placement="right"
      disabled={!shouldShowTooltip}
    >
      <DropdownLinkItem
        onClick={event => {
          // If is authorized then we use the `to` route
          if (isAuthorizedWorkspace) return

          event.preventDefault()
          signIn({ workspace })
        }}
        to={workspaceRoute}
        isActive={() => isSelectedWorkspace}
        $expired={!isWorkspaceActive}
        unmountAfterClick={true}
      >
        <DropdownLinkItemMainContent>
          {isPersonalWorkspace(workspace) ? (
            <WorkspaceLogo.Icon
              icon="briefcase"
              size="24px"
              UNSAFE_ADD_MARGIN
            />
          ) : (
            <WorkspaceLogo
              workspaceName={name}
              src={avatar?.large}
              size="24px"
              UNSAFE_ADD_MARGIN
            />
          )}

          <WorkspaceName
            ref={truncatedNodeRef}
            $isSignedOutLabelVisible={!isAuthorizedWorkspace}
          >
            {name}
          </WorkspaceName>
          {!isWorkspaceActive && <ExpiredLabel />}
          {/* Guests and Partners users don't have access to SSO information */}
          {isSSOWorkspace && !isGuestWorkspace && !isPartnerWorkspace && (
            <SsoTag />
          )}
          {!isAuthorizedWorkspace && (
            <SignedOutLabel expired={!isWorkspaceActive} />
          )}
        </DropdownLinkItemMainContent>
        {shouldShowTrialPill && (
          <StyledTrialPill variant="black">Trial</StyledTrialPill>
        )}
      </DropdownLinkItem>
    </Tooltip>
  )
}

const WorkspaceDropdownContent = ({ workspace }: WorkspacesDropdownProps) => {
  const { data } = useGetInitialUserQuery()
  const isSignedIn = useUserSignedIn()
  const isPartnerSupport = useFlag('partner-and-resellers-support')
  const { workspaces, loading: workspacesLoading } = useGetWorkspaces({
    skip: !isSignedIn || IS_EMBEDDED,
  })

  const { canSubscribeStripe } = useCanSubscribeViaStripe(
    workspace.identifier,
    workspace.customer?.identifier
  )

  const hasPersonalIdentity = data?.me.hasPersonalIdentity

  const [personalWorkspace, memberWorkspaces, guestWorkspaces] = useMemo(() => {
    const personalWorkspace = getPersonalWorkspace(workspaces)
    const memberWorkspaces = getMemberWorkspaces(workspaces)
    const guestWorkspaces = getGuestWorkspaces(workspaces)

    return [personalWorkspace, memberWorkspaces, guestWorkspaces] as const
  }, [workspaces])

  const canCreateWSPartner =
    !data?.me?.isPartner || (data?.me?.isPartner && isPartnerSupport)
  return (
    <DropdownContentContainer data-testid="workspace-dropdown">
      {/* Personal workspace */}
      {personalWorkspace && (
        <>
          <WorkspaceDropdownItem
            key={personalWorkspace.identifier}
            selectedWorkspace={workspace}
            workspace={personalWorkspace}
          />
          <DropdownDivider />
        </>
      )}

      {/* Member workspaces */}
      {memberWorkspaces.length > 0 && (
        <DropdownHeader>Member of</DropdownHeader>
      )}
      {memberWorkspaces.map(workspaceItem => (
        <WorkspaceDropdownItem
          key={workspaceItem.identifier}
          selectedWorkspace={workspace}
          workspace={workspaceItem}
        />
      ))}

      {memberWorkspaces.length > 0 && guestWorkspaces.length > 0 && (
        <DropdownDivider />
      )}

      {/* Guest workspaces */}
      {guestWorkspaces.length > 0 && <DropdownHeader>Guest in</DropdownHeader>}
      {guestWorkspaces.map(workspaceItem => (
        <WorkspaceDropdownItem
          key={workspaceItem.identifier}
          selectedWorkspace={workspace}
          workspace={workspaceItem}
        />
      ))}

      {workspacesLoading && (
        <Flex justifyContent="center" flex="auto">
          <LoadingPlaceholder size="24px" />
        </Flex>
      )}

      {/* Workspace creation */}
      {hasPersonalIdentity && canSubscribeStripe && canCreateWSPartner && (
        <CreateWorkspaceButtonWrapper>
          <DropdownDivider />
          <DropdownLinkItem to={routes.WORKSPACE_CREATE.create({})}>
            <WorkspaceLogo.Icon size="24px" icon="plus" UNSAFE_ADD_MARGIN />
            <div>
              Create Workspace
              {!(
                (data?.me?.isPartner && isPartnerSupport) ||
                isBillingHidden
              ) && (
                <DrodpwonItemSubtitle>
                  30-Day trial available
                </DrodpwonItemSubtitle>
              )}
            </div>
          </DropdownLinkItem>
        </CreateWorkspaceButtonWrapper>
      )}
    </DropdownContentContainer>
  )
}

interface WorkspaceIconTooltipProps {
  isDropdownOpen: boolean
}

const WorkspaceIconTooltip: React.FC<WorkspaceIconTooltipProps> = ({
  children,
  isDropdownOpen,
}) => (
  <Tooltip
    content="Switch Workspace"
    placement="bottom"
    disabled={isDropdownOpen}
    disableWhenTouchDevice
  >
    {children}
  </Tooltip>
)

/**
 * MAIN COMPONENT
 */
export const WorkspacesDropdown = ({
  workspace,
  size = 32,
}: WorkspacesDropdownProps) => {
  const [
    responsiveWorkspaceDropdown,
    buttonProps,
    { visible },
  ] = useResponsiveDropdown({
    dropdown: WorkspaceDropdownContent,
    dropdownProps: {
      workspace,
    },
    placement: 'bottom',
    dropdownStyle: {
      padding: 0,
    },
  })

  return (
    <>
      <DropdownTarget {...buttonProps}>
        <DropdownInnerContainer>
          <WorkspaceIconTooltip isDropdownOpen={visible}>
            {isPersonalWorkspace(workspace) ? (
              <WorkspaceLogo.Icon
                icon="briefcase"
                size={`${size}px`}
                UNSAFE_ADD_MARGIN
              />
            ) : (
              <WorkspaceLogo
                workspaceName={workspace.name}
                src={workspace.avatar?.large}
                size={`${size}px`}
                UNSAFE_ADD_MARGIN
              />
            )}
          </WorkspaceIconTooltip>
        </DropdownInnerContainer>
      </DropdownTarget>
      {responsiveWorkspaceDropdown}
    </>
  )
}
