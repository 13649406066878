import { jsxs, jsx } from 'react/jsx-runtime';
import styled from 'styled-components';
import '../LoadingPlaceholder/index.js';
import { LoadingPlaceholder } from '../LoadingPlaceholder/LoadingPlaceholder.js';

const LoadingWrapper = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;
const StyledMessageContainer = styled.div `
  position: absolute;

  /* 16px for extra space between the logo and text */
  margin-top: calc(74px + 16px);
`;
const LoadingState = ({ message, size = '64px', type = 'bar', }) => (jsxs(LoadingWrapper, { children: [jsx(LoadingPlaceholder, { size: size, type: type }), message && jsx(StyledMessageContainer, { children: message })] }));

export { LoadingState, LoadingWrapper };
