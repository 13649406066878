import { jsxs, jsx } from 'react/jsx-runtime';
import * as yup from 'yup';
import { Formik } from 'formik';
import { passwordStrengthValidation, Box, CreatePasswordInput, Button } from '@sketch/components';
import { routes, IndexLayoutContent, IndexLayoutHeaderLink, IndexLayoutOldTitle } from '@sketch/modules-common';
import { useToast } from '@sketch/toasts';
import { clearResetToken, getResetToken } from '@sketch/utils';
import { useUserPasswordResetMutation } from '@sketch/gql-types';
import { Form } from './ResetPasswordView.styles.js';

const resetPasswordSchema = yup.object().shape({
    password: yup
        .string()
        .min(8, 'Password is too short (minimum is 8 characters)')
        .max(72, 'Password is too long (maximum is 72 characters)')
        .test('passwordStrength', 'The password strength should be at least Okay', passwordStrengthValidation)
        .required('Password can’t be blank'),
});
const INITIAL_VALUES = {
    password: '',
};
const ResetPasswordView = props => {
    var _a;
    const { history, HeaderPortal, location } = props;
    const { showToast } = useToast();
    const [resetPassword, { loading, data }] = useUserPasswordResetMutation({
        redirectErrors: false,
        onError: error => {
            showToast(error.message, 'negative');
        },
        onCompleted: data => {
            const { successful } = data.userPasswordReset || {};
            if (successful) {
                showToast('Your password has been reset! Please sign in.');
                history.push(routes.SIGN_IN.create({}));
                // Clear the token from local-storage
                clearResetToken();
            }
        },
    });
    const apiErrors = ((_a = data === null || data === void 0 ? void 0 : data.userPasswordReset) === null || _a === void 0 ? void 0 : _a.errors) || [];
    const handleSubmit = (values) => {
        /**
         * Check for reset token presence and
         * handle it to prevent referrer leak
         * https://github.com/sketch-hq/Cloud/issues/3898
         */
        const token = getResetToken(location);
        if (!token) {
            showToast('Something is wrong, please contact our support team', 'negative');
            return;
        }
        resetPassword({
            variables: {
                newPassword: values.password,
                token,
            },
        });
    };
    return (jsxs(IndexLayoutContent, Object.assign({ center: "horizontal", marginTop: true, paddingHorizontal: true }, { children: [jsx(HeaderPortal, { children: jsx(IndexLayoutHeaderLink, { headerLink: "sign-in" }) }), jsx(IndexLayoutOldTitle, { children: "Enter Your New Password" }), jsx(Formik, Object.assign({ initialValues: INITIAL_VALUES, validationSchema: resetPasswordSchema, onSubmit: handleSubmit }, { children: ({ values, errors, touched, dirty, isValid, handleChange, handleBlur, }) => (jsxs(Form, { children: [jsx(Box, Object.assign({ mb: 6 }, { children: jsx(CreatePasswordInput, { label: "New Password", name: "password", value: values.password, onChange: handleChange, onBlur: handleBlur }) })), apiErrors.length > 0 && (jsx(Form.ErrorField, { children: apiErrors.map(error => error === null || error === void 0 ? void 0 : error.message).join('; ') })), jsx(Form.Footer, { children: jsx(Button, Object.assign({ variant: "primary", fill: true, type: "submit", loading: loading, disabled: !(isValid && dirty) }, { children: "Change Password" })) })] })) }))] })));
};

export { ResetPasswordView as default };
