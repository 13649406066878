import { __awaiter } from 'tslib';
import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Form, Input, Button } from '@sketch/components';
import { validEmail } from '@sketch/utils';
import { useUserRequestPasswordResetMutation } from '@sketch/gql-types';

const forgotPasswordSchema = yup.object().shape({
    email: validEmail().required('Email can’t be blank'),
});
const Fields = ({ values, errors, touched, handleChange, handleBlur, }) => (jsx(Form.Field, Object.assign({ name: "email", label: "Email", errorText: touched.email ? errors.email : undefined }, { children: jsx(Input, { name: "email", type: "email", placeholder: "john@appleseed.com", value: values.email, onChange: handleChange, onBlur: handleBlur, autoFocus: true }) })));
const Submit = ({ isSubmitting, isValid, dirty, }) => (jsx(Form.Footer, { children: jsx(Button, Object.assign({ variant: "primary", type: "submit", fill: true, size: "40", disabled: isSubmitting || !(isValid && dirty), loading: isSubmitting }, { children: "Reset Password" })) }));
const defaultFormFields = (formProps) => (jsxs(Fragment, { children: [jsx(Fields, Object.assign({}, formProps)), jsx(Submit, Object.assign({}, formProps))] }));
const ForgotPasswordForm = ({ children = defaultFormFields, initialEmail, onError, onCompleted, }) => {
    var _a, _b;
    const [resetPassword, { loading, data },] = useUserRequestPasswordResetMutation({
        redirectErrors: false,
        onCompleted,
        onError,
    });
    const apiErrors = ((_a = data === null || data === void 0 ? void 0 : data.userRequestPasswordReset) === null || _a === void 0 ? void 0 : _a.errors) || [];
    const successful = !!((_b = data === null || data === void 0 ? void 0 : data.userRequestPasswordReset) === null || _b === void 0 ? void 0 : _b.successful);
    return (jsx(Formik, Object.assign({ initialValues: { email: initialEmail || '' }, onSubmit: ({ email }) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                yield resetPassword({ variables: { email } });
            }
            catch (e) {
                // The error will be handled by the mutation "onError"
            }
        }), validationSchema: forgotPasswordSchema, validateOnBlur: false }, { children: formikBag => (jsx(Form, { children: children(Object.assign(Object.assign({}, formikBag), { isSubmitting: loading, successful,
                apiErrors })) })) })));
};

export { Fields, ForgotPasswordForm, Submit };
