import { DataProxy } from 'apollo-cache'

import {
  ProjectFragment,
  ProjectFragmentDoc,
  CollectionFragment,
  CollectionFragmentDoc,
} from '@sketch/gql-types'
import { dataIdFromObject } from '@sketch/graphql-cache'

import { removeFromPaginated } from '@sketch/modules-common'
import { deleteWorkspaceProject, restoreWorkspaceProject } from '..'

interface ProjectCacheOperation {
  cache: DataProxy
  identifier: string
}

interface ProjectRestoredOperation extends ProjectCacheOperation {
  workspaceIdentifier: string
}

interface ProjectDeletedOperation extends ProjectRestoredOperation {
  projectData: ProjectFragment
}

export const handleProjectDeleted = (params: ProjectDeletedOperation) => {
  const { cache, identifier, workspaceIdentifier } = params

  deleteWorkspaceProject(cache, workspaceIdentifier, identifier)
}

export const handleProjectRestored = (params: ProjectRestoredOperation) => {
  const { cache, identifier, workspaceIdentifier } = params

  restoreWorkspaceProject(cache, workspaceIdentifier, identifier)
}

export const handleProjectDeletedPermanently = (
  params: ProjectCacheOperation
) => {
  const { cache, identifier } = params

  // Remove the project from the trash query
  removeFromPaginated(cache, { __typename: 'Project', identifier }, key =>
    key.includes('workspaceTrash')
  )
}

export const getCachedProject = (params: ProjectCacheOperation) => {
  const { cache, identifier } = params

  try {
    return (
      cache.readFragment<ProjectFragment>({
        fragment: ProjectFragmentDoc,
        fragmentName: 'Project',
        id: dataIdFromObject({
          __typename: 'Project',
          identifier,
        })!,
      }) || undefined
    )
  } catch (e) {
    // This is a preventive way of returning an error to the UI
    // If this returns an error we simulate that the object does not exist
    return undefined
  }
}

export const getCachedCollection = (params: ProjectCacheOperation) => {
  const { cache, identifier } = params

  try {
    return (
      cache.readFragment<CollectionFragment>({
        fragment: CollectionFragmentDoc,
        fragmentName: 'Collection',
        id: dataIdFromObject({
          __typename: 'Collection',
          identifier,
        })!,
      }) || undefined
    )
  } catch (e) {
    // This is a preventive way of returning an error to the UI
    // If this returns an error we simulate that the object does not exist
    return undefined
  }
}
