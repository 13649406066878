import { jsx } from 'react/jsx-runtime';
import { useContext } from 'react';
import { LoadingPageContext } from './LoadingPageContext.js';

/**
 * This component loads through context a different loading page based on the current
 * route.
 * See RouteBasedLoadingPage.tsx to configure what loading page to use for
 * your route.
 */
const DynamicLoadingPage = (props) => {
    const { component: Component } = useContext(LoadingPageContext);
    // Typescript safeguard
    if (props && 'routeKey' in props) {
        return jsx(Component, Object.assign({}, props));
    }
    return jsx(Component, {});
};

export { DynamicLoadingPage };
