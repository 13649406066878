import React from 'react'
import { ApolloError } from 'apollo-client'

// External components
import {
  Flex,
  Text,
  TimeAgo,
  ErrorMessage,
  LoadingState,
} from '@sketch/components'
import EditorView from '../../views/EditorView'
import DocumentItemsLayout from '../../../shares/components/DocumentItemsLayout'
import { DocumentItemDropdown } from '../../../shares/components/DocumentItemDropdown'
import { Header } from '../DesignSystem/Header'
import { ErrorHandler } from '@sketch/tracing'
import EmptyState from '../../../shares/components/EmptyState'
import { DsmTextEditor } from '../../components'
import {
  useFlag,
  EmbeddedErrorPage,
  RouteProps,
  GenericErrorView,
} from '@sketch/modules-common'

import {
  Content,
  WrapperDocs,
  LogoDocs,
  SeparatorDocs as Separator,
} from './DesignSystemWithDocs.styles'

import { DesignSystem as DesignSystemType } from '../../types'

import {
  GetDesignSystemQuery,
  useGetDesignSystemSectionsQuery,
} from '@sketch/gql-types'
import { PrivateWorkspaceRouteExtraProps } from '../../../workspace/containers'
import { DocumentSidebarLayoutExtraProps } from '../../../shares/components/DocumentSidebarLayout'

export interface DesignSystemProps
  extends RouteProps<'DESIGN_SYSTEM'>,
    PrivateWorkspaceRouteExtraProps {
  designSystemData: GetDesignSystemQuery | undefined
  designSystemLoading: boolean
  designSystemError: ApolloError | undefined
  loadMoreHandler: () => Promise<any>
  search: string
  layoutProps: DocumentSidebarLayoutExtraProps
}

export const DesignSystemWithDocs: React.FC<DesignSystemProps> = ({
  workspace,
  designSystemData,
  designSystemLoading,
  designSystemError,
  loadMoreHandler,
  search,
  layoutProps,
}) => {
  const isDesignSystemsEditorOn = useFlag('design-systems-editor')
  // gets the sections and their pages in detail to show on the sidebar
  const {
    data,
    loading: designSystemSectionsLoading,
    error,
  } = useGetDesignSystemSectionsQuery({
    variables: {
      identifier: designSystemData?.designSystem.identifier!,
    },
    skip: !isDesignSystemsEditorOn || !designSystemData,
    fetchPolicy: 'network-only',
  })

  // // All these checks need to be here because at the time the
  // // getDesignSystem query is executed, there is no layout yet
  // if (loading || (designSystemLoading && !designSystemData)) {
  //   return <LoadingState />
  // }
  const loading =
    designSystemSectionsLoading || (designSystemLoading && !designSystemData)

  if (!designSystemData) {
    return <ErrorMessage.Generic />
  }

  if (designSystemError) {
    return <GenericErrorView error={designSystemError} isInLayout />
  }

  const designSystem = designSystemData.designSystem
  const designSystemLibraries = designSystem?.shares.entries || []
  const totalShareCount = designSystem?.shares.meta.totalCount || 0
  const sections = data?.designSystem?.sections ?? []
  const userIsGuest =
    workspace?.userRole === 'GUEST' || workspace?.userRole === undefined

  if (error || !isDesignSystemsEditorOn) {
    return <EmbeddedErrorPage for="unknown" />
  }

  return (
    <EditorView
      layoutProps={layoutProps}
      designSystem={designSystem as DesignSystemType}
      workspace={workspace}
      sections={sections}
    >
      <WrapperDocs>
        <Header
          headerURL={designSystem.header}
          designSystemId={designSystem.identifier}
          userIsGuest={userIsGuest}
        />
        <Content>
          {designSystem.preview && <LogoDocs src={designSystem.preview} />}
          <Text textStyle="header.primary.I" mr={3} pt={6}>
            {designSystem?.name}
          </Text>
          <Text textStyle="header.primary.g" color="foreground.secondary.C">
            {designSystem?.description || 'No description added.'}
          </Text>
          <Text textStyle="copy.quaternary.standard.C" mt={3}>
            Updated <TimeAgo date={designSystem?.updatedAt} />
          </Text>
          <Separator />
          <DsmTextEditor content={JSON.parse(designSystem?.content || '{}')} />
          <Separator />
          <Text textStyle="header.primary.H" mt={8}>
            Libraries
          </Text>
          <Text textStyle="copy.tertiary.standard.F">
            Libraries contain components (Symbols, Text Styles, Color Variables
            and Layer Styles) that you can easily share across all your other
            documents.
          </Text>
          <Flex flexDirection="column" flex="auto" pt={8}>
            {loading ? (
              <LoadingState />
            ) : totalShareCount ? (
              <DocumentItemsLayout
                shares={designSystemLibraries}
                renderDropdown={({ identifier }) => {
                  const share = designSystemLibraries.find(
                    library => library.identifier === identifier
                  )
                  if (!share) {
                    ErrorHandler.shouldNeverHappen(
                      'Library should always be present rendering the DocumentDropdown'
                    )
                    return null
                  }
                  return (
                    <DocumentItemDropdown
                      share={share}
                      workspaceIdentifier={workspace.identifier}
                      // TODO: We won't be pinning DS yet
                      queryVariables={{}}
                      workspaceStatus={workspace.status}
                    />
                  )
                }}
                onLoadMore={loadMoreHandler}
                totalCount={totalShareCount}
                showProjectName={false}
                customColumns={[2, 2, 2, 2, 2, 3, 4, 5]}
              />
            ) : (
              <EmptyState
                search={search}
                title="No Libraries available to view yet"
                description="Libraries shared with you in this Workspace will appear here"
                icon="link"
              />
            )}
          </Flex>
        </Content>
      </WrapperDocs>
    </EditorView>
  )
}
